import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { ReactComponent as InsightImg } from '../../../../../../../../src/assets/icons/Insight.svg';
import { useSubscriptionDetail } from '../../../../../../../contexts/subscription.context';
import { RootState, useAppDispatch } from '../../../../../../../Redux/store';
import { StarIcon } from '../../../../../../basic/StarIcon.component';
import { SubScriptionType } from '../../../types';
import ReportList from '../OversightSummaries/ReportList';
import '../../../components/orbit/evaAnalysisStyle.css';
import ChatInput from './ChatInput';
interface OversightProps {
    setOversightSortVal?: React.Dispatch<React.SetStateAction<string>>;
    setOversightReportVal?: React.Dispatch<React.SetStateAction<string>>;
    oversightReportVal?: string;
    screen?: string;
    fetchOversight?: (type: string) => void;
    setScreen?: React.Dispatch<React.SetStateAction<string>>;
}

const Oversight: React.FC<OversightProps> = ({ setOversightSortVal, fetchOversight, oversightReportVal, screen }) => {
    const sortType = ['Date', 'Sort A-Z', 'Sort Z-A', 'Recommended', 'Started', 'Not started'];
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const subscription = useSubscriptionDetail();
    const isShield = subscription?.productName === SubScriptionType?.SHIELD;

    return (
        <>
            <div className='summaries_heading flex justify-between items-center'>
                <div className='flex items-center gap-3'>
                    <h1> {t('reports.reporting')} </h1>
                </div>
            </div>

            <div className={`${isShield ? 'shield_oversight' : ''} main_msg_details `}>
                <ChatInput />

                {isShield ? (
                    <>
                        <div className='data_unavailable text-center '>
                            <div className='img_box relative flex items-center justify-center'>
                                <InsightImg />
                                <div className='isShield_start'>
                                    <Link to={`/organisation/${id}/change-tier`}>
                                        <StarIcon blackIcon={true} isChangePlusIcon={true} isHover={true} />
                                    </Link>
                                </div>
                            </div>
                            <h1>{t('no_insight_available')} </h1>
                            <h6>{t('Interact_eva')}</h6>
                        </div>
                    </>
                ) : (
                    <ReportList />
                )}
            </div>
        </>
    );
};

export default Oversight;
