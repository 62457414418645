import { useState } from 'react';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Menu from '@mui/material/Menu';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as FilterIcon } from '../../../../../../../assets/icons/icon-filter-with-arrow.svg';
import { ReactComponent as RangeSortIconFill } from '../../../../../../../assets/icons/RangeSortIconFill.svg';
import { RootState } from '../../../../../../../Redux/store';
import { ActivityOption } from '../../../types';
import { activityOption } from './ActivityOption';

export enum ActivityFilterType {
    Favorite = 'Favorite',
    Update = 'Update',
    Creation = 'Creation',
    Save = 'Save',
    Evaluation = 'Evaluation',
    Suggestion = 'Suggestion',
    Report = 'Report Generated',
}

const ActivityFilter = ({ handleFilterChange }: { handleFilterChange: (filterType: string) => void }) => {
    const { t } = useTranslation();
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const open = Boolean(anchor);
    const handleClose = () => {
        setAnchor(null);
    };
    const isOpenBluePrintModal = useSelector((state: RootState) => state.EvaInfo.isOpenBluePrintModal);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchor(event.currentTarget);
    };
    return (
        <div>
            <button
                className='flex items-center text-sm text-white text-opacity-75 hover:text-opacity-100 sort-btn'
                onClick={(e) => handleClick(e)}
            >
                {isOpenBluePrintModal ? <RangeSortIconFill /> : <FilterIcon className='w-3 h-3' fill='#0F104480' />}

                <KeyboardArrowDownOutlinedIcon
                    className='activity-sort-icon'
                    sx={{
                        color: '#0F104480',
                        fontSize: '1rem',
                    }}
                />
            </button>
            <Menu
                id='metric-sort-menu'
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    className: 'sort-menu-list',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                disableAutoFocus
            >
                {activityOption.map((item: ActivityOption, index) => (
                    <button
                        key={index} // Add a unique key
                        className='w-full flex text-sm p-2 text-darker text-opacity-75 hover:bg-primary-darker hover:bg-opacity-3'
                        onClick={() => {
                            handleFilterChange(item.type);
                            handleClose();
                        }}
                    >
                        <p> {t(`activityFilter.${item.name}`)} </p>
                    </button>
                ))}
            </Menu>
        </div>
    );
};

export default ActivityFilter;
