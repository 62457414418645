import { useTranslation } from 'react-i18next';

import AwardIcon from '../../../../../../../../src/assets/icons/AwardIcon.svg';
import AwardIconDisable from '../../../../../../../../src/assets/icons/AwardIconDisable.svg';
import CustomDisable from '../../../../../../../../src/assets/icons/Custom_disable.svg';
import EmptyProfile from '../../../../../../../../src/assets/icons/EmptyProfile.svg';
import GraphLine from '../../../../../../../../src/assets/icons/GraphLine.svg';
import GraphLineDisable from '../../../../../../../../src/assets/icons/GraphLineDisable.svg';
import ScoreDisable from '../../../../../../../../src/assets/icons/ScoreDisable.svg';
import ScoreEnable from '../../../../../../../../src/assets/icons/ScoreEnable.svg';
import StarUserDisable from '../../../../../../../../src/assets/icons/StarUserDisable.svg';
import { getDocumentUrl } from '../../../../../../../utils/getDocumentUrl';
import { Metric } from '../../../../evaAnalytics/components/analyticsChart/types';
import { MetricTypeIcon } from '../../MetricsConfig';

interface KpiMetricItemProps {
    metric: Metric;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OptionData?: any[];
}

const KpiMetricItem: React.FC<KpiMetricItemProps> = ({ metric, OptionData }) => {
    let kpiIcon, kpiValue;
    const { t } = useTranslation();

    switch (metric.name) {
        case 'Total Engagement Over Time':
            kpiIcon =
                metric?.value === null ? ScoreDisable : metric?.value?.total_percentage ? ScoreEnable : ScoreDisable;
            kpiValue = metric?.value ? `${metric?.value?.total_percentage}%` : '%';
            break;

        case 'Top Protocol Category':
            kpiIcon =
                metric?.value === null
                    ? AwardIconDisable
                    : (metric?.value !== null &&
                          OptionData?.find((item) => item?.name === metric?.value?.[0]?.type)?.fillIcon) ||
                      CustomDisable;
            kpiValue = metric?.value !== null ? metric?.value[0]?.type || t('no_protocol') : t('no_protocol');

            break;

        case 'Most Used Protocol via Phantom':
            kpiIcon = metric?.value === null ? AwardIconDisable : metric.value ? AwardIcon : AwardIconDisable;
            kpiValue = metric?.value ? 'NAMEOFPROTOCOLLOREMIPS' : t('no_protocol');

            break;

        case 'Top Contributor':
            kpiIcon = metric?.value?.documentId ? getDocumentUrl(+metric?.value?.documentId) : EmptyProfile;
            kpiValue = metric?.value?.firstName?.length > 0 ? metric?.value?.firstName : t('no_contributor');

            break;

        case 'Compliance Score':
            kpiIcon = metric?.value === null ? ScoreDisable : metric?.value?.average_score ? ScoreEnable : ScoreDisable;
            kpiValue = metric?.value ? `${metric?.value?.average_score}%` : '%';

            break;

        case 'Risk Trend':
            kpiIcon = metric?.value === null ? GraphLineDisable : metric?.value ? GraphLine : GraphLineDisable;
            kpiValue = metric?.value ? `${metric.value}%` : '%';
            break;

        case 'Latest Member Joining':
            kpiIcon =
                metric?.value === null
                    ? StarUserDisable
                    : metric?.value?.documentId
                    ? getDocumentUrl(+metric?.value?.documentId)
                    : EmptyProfile;
            kpiValue = metric?.value?.firstName?.length > 0 ? metric?.value?.firstName : t('no_name');

            break;

        default:
            kpiIcon =
                metric?.format === null ? ScoreDisable : metric.format ? MetricTypeIcon(metric.format) : ScoreDisable;

            kpiValue = metric && metric?.name ? `${metric.name}` : t('no_name');
            break;
    }

    return (
        <p className='flex gap-1 items-center my-1'>
            <div className='bg-center bg-no-repeat bg-white bg-cover rounded-full '>
                <img className='report_list_tooltip_img' src={kpiIcon && kpiIcon} alt={`${metric.name} Icon`} />

            </div>
            <span>{kpiValue ? kpiValue : ''}</span>
        </p>
    );
};

export default KpiMetricItem;
