import './CtrlLeftSidePanel.css';
import { useEffect, useRef } from 'react';

import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as OpenDoorIcon } from '../../../../../../../../src/assets/icons/DoorOpen.svg';
import { ReactComponent as PlusIcon } from '../../../../../../../../src/assets/icons/icon-nuance-trigger-plus.svg';
import { ReactComponent as ProtocolIcon } from '../../../../../../../../src/assets/icons/Protocols_icon.svg';
import {
    setAddNewProtocol,
    setConfirmationScreen,
    setFileError,
    setMetaMarkerValidation,
    setOpenLeftSidePanel,
    setProtocolEditMode,
    setProtocolErrorMessage,
    setProtocolIndex,
    setSaveConfirmModal,
} from '../../../../../../../Redux/EvaInfo';
import { RootState, useAppSelector } from '../../../../../../../Redux/store';
import Sidebar from '../../Sidebar';
import LeftSideProtocolList from './LeftSideProtocolList';
import ProtocolDetail from './ProtocolDetail';

interface CtrlLeftSidePanelProps {
    setDisableArrow?: React.Dispatch<React.SetStateAction<boolean>>;
    disableArrow?: boolean;
    oversight?: boolean;
}

const CtrlLeftSidePanel: React.FC<CtrlLeftSidePanelProps> = ({ setDisableArrow, disableArrow, oversight }) => {
    const protocolIndex = useAppSelector((state: RootState) => state.EvaInfo.protocolIndex);
    const isOpenBluePrintModal = useAppSelector((state: RootState) => state.EvaInfo.isOpenBluePrintModal);
    const isProtocolEditMode = useAppSelector((state: RootState) => state.EvaInfo.isProtocolEditMode);
    const isOpenLeftSidePanel = useAppSelector((state: RootState) => state.EvaInfo.isOpenLeftSidePanel);
    const isChangeProtocolData = useAppSelector((state: RootState) => state.EvaInfo.isChangeProtocolData);
    const bluePrintLoading = useAppSelector((state: RootState) => state.EvaInfo.bluePrintEvaLoading);
    const addProtocolLoading = useAppSelector((state: RootState) => state.EvaInfo.addProtocolLoading);
    const uploadFileLoading = useAppSelector((state: RootState) => state.EvaInfo.uploadFileLoading);
    const isEvaluateMode = useAppSelector((state: RootState) => state.EvaInfo.isEvaluateMode);

    const leftPanelRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    const onClickOpen = (value: string) => {
        if (isChangeProtocolData && isProtocolEditMode) {
            dispatch(setSaveConfirmModal(true));
        } else {
            dispatch(setOpenLeftSidePanel(isOpenLeftSidePanel === value ? '' : value));
            dispatch(setFileError(null));
            dispatch(setProtocolErrorMessage(null));
            dispatch(setMetaMarkerValidation(false));
            if (isOpenLeftSidePanel == value) {
                dispatch(setProtocolEditMode(false));
                dispatch(setProtocolIndex(null));
            }
        }
    };

    useEffect(() => {
        if (isOpenBluePrintModal) {
            document.documentElement.id = 'edit-mode';
        } else {
            document.documentElement.id = 'ctrl';
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenBluePrintModal, dispatch]);

    const handleClickOutsideLeftPanel = (event: MouseEvent) => {
        if (!uploadFileLoading && !addProtocolLoading) {
            if (leftPanelRef.current && !leftPanelRef.current.contains(event.target as Node)) {
                // Click occurred outside the left panel

                if (isEvaluateMode && isChangeProtocolData) {
                    dispatch(setSaveConfirmModal(true));
                } else if (isEvaluateMode) {
                    dispatch(setSaveConfirmModal(false));
                } else {
                    dispatch(setFileError(null));
                    dispatch(setProtocolErrorMessage(null));
                    dispatch(setMetaMarkerValidation(false));
                    dispatch(setProtocolEditMode(false));
                    dispatch(setProtocolIndex(null));
                    dispatch(setConfirmationScreen(false));
                    dispatch(setOpenLeftSidePanel(''));
                }
            }
        }
    };

    useEffect(() => {
        if (isProtocolEditMode) {
            document.addEventListener('mousedown', handleClickOutsideLeftPanel);
            return () => {
                document.removeEventListener('mousedown', handleClickOutsideLeftPanel);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChangeProtocolData, isProtocolEditMode, addProtocolLoading, uploadFileLoading, isEvaluateMode]);

    return (
        <>
            <Tooltip id='door_icon' offset={5} place='top' className='tooltip-box show_arrow'>
                <p>{isOpenLeftSidePanel ? t('close') : t('open')}</p>
            </Tooltip>
            <div
                ref={leftPanelRef}
                className={`main_left_panel  ${
                    bluePrintLoading || addProtocolLoading || uploadFileLoading ? 'disable_overly' : ''
                }  flex ${isOpenLeftSidePanel ? 'show_panel' : oversight ? 'close_panel' : ''} ${
                    oversight && 'oversight_mode'
                }`}
            >
                <div className='left_bar flex gap-4 flex-col'>
                    <button
                        data-tooltip-id='door_icon'
                        onClick={() => !oversight && onClickOpen('ProtocolList')}
                        className='door_btn flex justify-center items-center'
                    >
                        <OpenDoorIcon />
                    </button>

                    <div className='horizontal_line' />
                    <ul className='p-0 m-0'>
                        <li
                            data-tooltip-id='Protocols'
                            onClick={() => !oversight && onClickOpen('ProtocolList')}
                            className={` flex justify-center cursor-pointer items-center ${
                                isOpenLeftSidePanel === 'ProtocolList' ? 'active' : ''
                            }`}
                        >
                            <ProtocolIcon />
                        </li>
                    </ul>
                </div>

                {isOpenLeftSidePanel == 'ProtocolList' && (
                    <div className='right_content_panel'>
                        {isProtocolEditMode ? (
                            <>
                                <Sidebar disableArrow={disableArrow} setDisableArrow={setDisableArrow} />
                            </>
                        ) : (
                            <>
                                {protocolIndex !== null ? (
                                    <>
                                        {isOpenBluePrintModal ? (
                                            <>{protocolIndex !== null && !isProtocolEditMode && <ProtocolDetail />}</>
                                        ) : (
                                            <ProtocolDetail />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div className='heading flex justify-between items-center'>
                                            <h6>{t('protocols')}</h6>
                                            {isOpenBluePrintModal && (
                                                <div
                                                    className='plus_btn cursor-pointer flex justify-between items-center'
                                                    onClick={(e) => {
                                                        dispatch(setAddNewProtocol(e));
                                                    }}
                                                >
                                                    <PlusIcon className='block m-auto' />
                                                </div>
                                            )}
                                        </div>
                                        <LeftSideProtocolList />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default CtrlLeftSidePanel;
