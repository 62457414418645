import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as Document } from '../../../../../../../src/assets/icons/Document.svg';
import { ReactComponent as OpenDoorIcon } from '../../../../../../../src/assets/icons/DoorOpen.svg';
import { ReactComponent as ListIcon } from '../../../../../../../src/assets/icons/ListIcon.svg';
import {
    setChildScreen,
    setNewMetrics,
    setNewProtocols,
    setOversightOption,
    setPrvMetrics,
    setPrvProtocols,
    setReportLoading,
    setScreen,
    setSelectMetrics,
    setSessionsId,
    setSessionsReport,
} from '../../../../../../Redux/SessionsProtocols';
import { RootState, useAppDispatch } from '../../../../../../Redux/store';
import { Activity } from './Activity/Activity';
import OversightScreen from './Oversight/OversightScreen';
import './OversightSummaries/OversightSummaries.css';

interface OversightSummariesProps {
    reportError: string | undefined;
    setReportError: React.Dispatch<React.SetStateAction<string | undefined>>;
    fetchOversight: (type: string) => void;
    prompt: string;
    reportName: string;
}

const OversightSummaries: React.FC<OversightSummariesProps> = ({
    fetchOversight,
    reportError,
    prompt,
    reportName,
    setReportError,
}) => {
    const { t } = useTranslation();

    const screen = useSelector((state: RootState) => state.SessionsProtocols.screen);
    const childScreen = useSelector((state: RootState) => state.SessionsProtocols.childScreen);
    const [showScreen, setShowScreen] = useState(false);

    const dispatch = useAppDispatch();

    const openScreen = (screenName: string) => {
        if (screen === screenName) {
            setShowScreen(!showScreen);
        } else {
            dispatch(setScreen(screenName));
            setShowScreen(true);
        }
    };

    //if we have not any screen open like Insight or OverSight
    useEffect(() => {
        if (!showScreen) {
            dispatch(setSessionsId(null));
            dispatch(setNewProtocols([]));
            dispatch(setPrvProtocols([]));
            dispatch(setNewMetrics([]));
            dispatch(setSelectMetrics([]));
            dispatch(setPrvMetrics([]));
            dispatch(setSessionsReport(null));
            dispatch(setReportLoading(false));
            dispatch(setOversightOption(''));
            setShowScreen(false);
            dispatch(setScreen(''));

            dispatch(setChildScreen(''));
        }

        if (childScreen !== 'Report' && childScreen !== 'ViewHistoryReport') {
            dispatch(setNewProtocols([]));
            dispatch(setNewMetrics([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showScreen, childScreen]);

    //it is used for when screen is report List or history report list
    useEffect(() => {
        if (screen?.length > 0) {
            openScreen(screen);
            setShowScreen(true);
        }
        if (screen === '') {
            setShowScreen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen]);

    return (
        <>
            <Tooltip id='Oversight' offset={5} place='top' className='tooltip-box show_arrow'>
                <p> {t('oversight')} </p>
            </Tooltip>

            <Tooltip id='door_icon1' offset={5} place='top' className='tooltip-box show_arrow'>
                <p>{showScreen ? t('close') : t('open')}</p>
            </Tooltip>

            <Tooltip id='Activity' offset={5} place='top' className='tooltip-box show_arrow'>
                <p> {t('Activity')} </p>
            </Tooltip>
            <div className={`${showScreen ? 'rounded-15' : 'show_screen rounded-full'} oversight_summaries flex`}>
                {showScreen && (
                    <div className='inside_screen'>
                        {screen === 'Activity' && <Activity />}
                        {screen === 'Oversight' && (
                            <OversightScreen
                                setReportError={setReportError}
                                reportError={reportError}
                                prompt={prompt}
                                reportName={reportName}
                                fetchOversight={fetchOversight}
                            />
                        )}
                    </div>
                )}

                <div className='sidebar_tab flex items-center py-2 gap-4 flex-col '>
                    <button
                        data-tooltip-id='door_icon1'
                        onClick={() => openScreen(screen.length == 0 ? 'Activity' : screen)}
                        className='door_btn flex justify-center items-center'
                    >
                        <OpenDoorIcon />
                    </button>

                    <div className='horizontal_line' />

                    <ul className='mt-3'>
                        <li
                            className={` ${screen === 'Activity' ? 'active' : ''} relative`}
                            data-tooltip-id='Activity'
                            onClick={() => {
                                openScreen('Activity');
                            }}
                        >
                            <ListIcon />
                        </li>
                        <li
                            className={` ${screen === 'Oversight' ? 'active' : ''} relative`}
                            data-tooltip-id='Oversight'
                            onClick={() => {
                                openScreen('Oversight');
                            }}
                        >
                            <Document />
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default OversightSummaries;
