import '../Trends/Trends.css';
import { useState, useRef, useEffect, useCallback } from 'react';

import * as Sentry from '@sentry/react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import shieldTrend from '../../../../../../src/assets/icons/empty_trents.png';
import flag from '../../../../../../src/assets/icons/flag.svg';
import { ReactComponent as GreaterThansymbol } from '../../../../../../src/assets/icons/GreaterThansymbol.svg';
import { ReactComponent as BackIcon } from '../../../../../../src/assets/icons/icon-arrow-left-long.svg';
import { ReactComponent as LessThansymbol } from '../../../../../../src/assets/icons/LessThansymbol.svg';
import { appInsights } from '../../../../../AppInsights';
import { useSubscriptionDetail } from '../../../../../contexts/subscription.context';
import { useOrganisationForm } from '../../../../../hooks/useOrganisation';
import { RootState, useAppDispatch } from '../../../../../Redux/store';
import {
    setSelectedGraphData,
    setSelectedIds,
    setSelectedMetricGraphData,
    setSelectedProtocols,
    setSelectMetricProtocol,
} from '../../../../../Redux/TrendMetric';
import { EvaService } from '../../../../../services/eva/eva.service';
import { CustomLoader } from '../../../../basic/CustomeLoader/CustomLoader';
import HorizontalSeparator from '../../../../basic/HorizontalSeparator';
import { StarIcon } from '../../../../basic/StarIcon.component';
import { ReactComponent as SearchIcon } from '../.././../../../../src/assets/icons/serachIocn.svg';
import { OptionData } from '../../eva/components/ProtocolTypeList';
import {
    GraphProtocol,
    GraphProtocolTypePercentage,
    SubScriptionType,
    EvaGraphAnalyticsEvaList,
    ProtocolTypes,
    EvaType,
} from '../../eva/types';
import EmptyRisk from '../risk/EmptyRisk.component';
import { ReactComponent as InfoIcon } from './../../../../../assets/icons/info_Iocn.svg';
import Metrics from './Metrics';
import ProtocolTrendChart from './ProtocolTrendChart';
import TrendsFilter from './TrendsFilter';

const Trends = ({ evaId, titleList }: { evaId: string; titleList?: EvaGraphAnalyticsEvaList[] }) => {
    const { t } = useTranslation();
    const subscription = useSubscriptionDetail();
    const { id: organisationId } = useParams<{ id: string }>();
    const [timeFilter, setTimeFilter] = useState<string>('Day'); // Initial state for time filter
    const [search, setSearch] = useState('');
    const [filterType, setFilterType] = useState<string[]>([]);
    const [selectedTrendProtocols, setSelectedTrendProtocol] = useState<string[]>([]);
    const [showSearch, setShowSearch] = useState(false);
    const [selectPreviousProtocol, setSelectPreviousProtocol] = useState<string[]>([]);
    const [backTimeFilter, setBackTimeFilter] = useState<string[]>([]);
    const isSelectMetric = useSelector((state: RootState) => state.TrendMetric.selectedIds);
    const selectMetricProtocol = useSelector((state: RootState) => state.TrendMetric.selectMetricProtocol);
    const selectedGraphData = useSelector((state: RootState) => state.TrendMetric.selectedGraphData);
    const selectedMetricGraphData = useSelector((state: RootState) => state.TrendMetric.selectedMetricGraphData);
    const metricLoading = useSelector((state: RootState) => state.TrendMetric.metricLoading);

    const { drilledDate } = selectedGraphData;
    const { metricDrilledDate } = selectedMetricGraphData;
    const drillDatePayload = backTimeFilter.length === 0 ? '' : drilledDate;
    const metricDrilledDatePayload = backTimeFilter.length === 0 ? '' : metricDrilledDate;

    const dispatch = useAppDispatch();
    const timeZoneOffset = moment().utcOffset().toString();

    const isShield = subscription?.productName === SubScriptionType?.SHIELD;
    const evaService = new EvaService();

    const { id } = useParams<{ id: string }>();
    useEffect(() => {
        if (selectedTrendProtocols) {
            dispatch(setSelectedProtocols(selectedTrendProtocols));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTrendProtocols]);

    const handleBack = () => {
        const updatedTimeFilter = [...backTimeFilter]; // Clone the array
        const poppedValue = updatedTimeFilter.pop(); // Remove the last value
        if (poppedValue) {
            setTimeFilter(poppedValue);
            setBackTimeFilter(updatedTimeFilter); // Update the state with the modified array

            if (poppedValue === 'Month') {
                if (drilledDate) {
                    const formattedDate = moment(drilledDate, 'DD-MM-YYYY').format('MM-YYYY');
                    dispatch(
                        setSelectedGraphData({
                            value: null,
                            drilledDate: formattedDate,
                        }),
                    );
                } else if (metricDrilledDate) {
                    const formattedMetricDate = moment(metricDrilledDate, 'DD-MM-YYYY').format('MM-YYYY');
                    dispatch(
                        setSelectedMetricGraphData({
                            metricValue: null,
                            metricDrilledDate: formattedMetricDate,
                        }),
                    );
                }
            }
        }
    };

    useEffect(() => {
        setBackTimeFilter([]);
        setTimeFilter('Day');
        dispatch(setSelectMetricProtocol([]));
        dispatch(setSelectedIds([]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisationId]);

    const {
        data: triggerData,
        isLoading: loading,
        isFetching: fetching,
    } = useQuery(
        ['eva-protocol', evaId, timeFilter, timeZoneOffset],
        async () => {
            if (evaId) {
                try {
                    const data = await evaService.getEvaProtocolLog(
                        evaId,
                        timeFilter,
                        timeZoneOffset,
                        drillDatePayload,
                    );
                    return data;
                } catch (e) {
                    console.error(e);
                    if (e instanceof Error) {
                        Sentry.captureException(e);
                        appInsights.trackException({ error: e });
                    }
                    throw e;
                }
            }
            return null;
        },
        {
            enabled:
                backTimeFilter.length === 0 && (drilledDate === '' || drilledDate === null)
                    ? true
                    : backTimeFilter.length > 0 && drilledDate
                    ? true
                    : evaId
                    ? true
                    : false,
        },
    );

    const {
        data: triggerList,
        isLoading: loadingList,
        isFetching: fetchingList,
    } = useQuery(['eva-protocol-list', evaId, filterType, '', search], () => {
        try {
            if (evaId) {
                return evaService.getEvaProtocolList(evaId, EvaType.publish, filterType, '', search);
            }
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ error: e });
            }
        }
    });

    const toggleProtocol = (protocolName: string, index: number) => {
        setSelectPreviousProtocol((prev) =>
            prev?.includes(protocolName) ? prev.filter((name) => name !== protocolName) : [...prev, protocolName],
        );
        if (selectMetricProtocol.length === 0) {
            setSelectedTrendProtocol((prev) =>
                prev?.includes(protocolName) ? prev.filter((name) => name !== protocolName) : [...prev, protocolName],
            );
        }
    };

    useEffect(() => {
        if (selectMetricProtocol && triggerData) {
            const matchedProtocols = triggerList?.protocols
                .filter((item: { protocolName: string }) => selectMetricProtocol?.includes(item.protocolName))
                .map((item: { protocolName: string }) => item.protocolName);
            setSelectedTrendProtocol(matchedProtocols);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectMetricProtocol]);

    useEffect(() => {
        if (selectPreviousProtocol && !selectedTrendProtocols?.length && !selectMetricProtocol?.length) {
            setSelectedTrendProtocol(selectPreviousProtocol);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectPreviousProtocol, selectedTrendProtocols]);

    const toggleFilterType = (type: string) => {
        setFilterType((prev) => (prev?.includes(type) ? prev.filter((name) => name !== type) : [...prev, type]));
    };

    const getFilteredProtocolsList = () => {
        if (triggerData) {
            return triggerData.protocols.filter((protocol: GraphProtocol) =>
                selectedTrendProtocols?.includes(protocol?.protocolName),
            );
        }
        return [];
    };

    if (isShield) {
        return (
            <div className='trends_box scroll_div '>
                <div className='trend_empty '>
                    <div className='empty_select ' />

                    <div className='relative'>
                        <div className='navi_empty_heading flex gap-2 items-center'>
                            <h1> {t('triggering_frequency')} </h1> <InfoIcon className='cursor-pointer info_icon' />
                            <div className='navi_info_box'>{t('protocol_frequency')}</div>
                        </div>
                        <img className='shield_trend' src={shieldTrend} alt='' />
                        <div className='isShield_start  '>
                            <Link to={`/organisation/${id}/change-tier`}>
                                <StarIcon blackIcon={true} isChangePlusIcon={true} isHover={true} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='h-full'>
            <div className='trends_box scroll_div h-full'>
                {titleList && titleList?.length > 0 && (
                    <div className='flex justify-end items-center  calender_filter'>
                        <div className='flex gap-2 items-center'>
                            {backTimeFilter.length === 0 ? (
                                ''
                            ) : (
                                <button
                                    className='back-btn'
                                    onClick={handleBack}
                                    disabled={loading || fetching || metricLoading}
                                >
                                    <BackIcon />
                                </button>
                            )}

                            <TrendsFilter
                                setTimeFilter={setTimeFilter}
                                timeFilter={timeFilter}
                                setBackTimeFilter={setBackTimeFilter}
                            />
                        </div>
                    </div>
                )}

                {titleList && titleList.length > 0 ? (
                    <div className='trends_protocol trends_protocol_box relative  h-full'>
                        <div className='scroll_trend_protocol scrollbar'>
                            {fetching && (
                                <div className='trend_loading_box'>
                                    <div id='loading' />
                                </div>
                            )}
                            <Tooltip id='trend_tooltip' offset={5} place='right' className='tooltip-box show_arrow'>
                                <p>{t('protocol_frequency')}</p>
                            </Tooltip>
                            <div className='trends_head mb-5 pb-2 flex justify-between items-center'>
                                <h2 className='flex items-center gap-1'>
                                    {t('triggering_frequency')}
                                    <InfoIcon className='ml-2 cursor-pointer' data-tooltip-id='trend_tooltip' />
                                </h2>
                                <div className='trend_top_box  w-full flex  items-center flex-wrap justify-end'>
                                    <div
                                        className={` search_box d-flex pr-3 items-center relative ${
                                            showSearch ? 'show_search' : ''
                                        }`}
                                    >
                                        <div className='searchIocn' onClick={() => setShowSearch(!showSearch)}>
                                            <SearchIcon />
                                        </div>
                                        <input
                                            type='text'
                                            onChange={(e) => setSearch(e.target.value)}
                                            placeholder={t('search')}
                                        />
                                    </div>

                                    <div className='type_box flex items-center filter_box gap-2  '>
                                        {OptionData?.map((item, index) => {
                                            const isActive = filterType?.includes(item.name);
                                            return (
                                                <button
                                                    className={`relative ${isActive ? 'active' : ''}`}
                                                    onClick={() => toggleFilterType(item?.name)}
                                                >
                                                    <div className='tooltip_box'>{t(`protocolTypes.${item.name}`)}</div>
                                                    <img src={item?.icon} />
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className='trends_protocol_list'>
                                <div
                                    className={`main_grid_box scrollbar ${
                                        isSelectMetric.length > 0 ? 'less_height' : ''
                                    }`}
                                >
                                    {loadingList || fetchingList ? (
                                        <CustomLoader />
                                    ) : (
                                        <>
                                            {triggerList &&
                                            triggerList?.protocols &&
                                            triggerList?.protocols.length > 0 ? (
                                                <div className='grid_box '>
                                                    {triggerList?.protocols.map(
                                                        (data: GraphProtocol, index: number) => {
                                                            const type =
                                                                typeof data.type === 'object' && 'error' in data.type
                                                                    ? 'Signal'
                                                                    : data.type;

                                                            const getIcon = OptionData.find(
                                                                (item) => item.name === type,
                                                            );
                                                            const isActive = selectedTrendProtocols?.includes(
                                                                data.protocolName,
                                                            );

                                                            let typeClass = '';
                                                            if (type === ProtocolTypes.Policy) {
                                                                typeClass = 'policy_color';
                                                            } else if (type === ProtocolTypes.GoodPractice) {
                                                                typeClass = 'good_practice_color';
                                                            } else if (type === ProtocolTypes.Critical) {
                                                                typeClass = 'critical_thinking_color';
                                                            } else if (type === ProtocolTypes.Signal) {
                                                                typeClass = 'signal_color';
                                                            }

                                                            const TotalTriggerNo = triggerData?.protocols?.find(
                                                                (item: GraphProtocol) =>
                                                                    item.protocolName == data.protocolName,
                                                            )?.totalTrigger;

                                                            return (
                                                                <div
                                                                    key={index} // Always include a unique key for list items
                                                                    className={`box relative ${
                                                                        isActive ? `${typeClass} active_box` : typeClass
                                                                    }`}
                                                                    onClick={() => {
                                                                        if (selectMetricProtocol.length > 0) {
                                                                            dispatch(setSelectMetricProtocol([]));
                                                                            dispatch(setSelectedIds([]));
                                                                        }
                                                                        toggleProtocol(data.protocolName, index);
                                                                    }}
                                                                >
                                                                    <h1>
                                                                        <img src={getIcon?.icon} alt={getIcon?.icon} />
                                                                        <span>{data.protocolName}</span>
                                                                    </h1>

                                                                    <div className='flex gap-3'>
                                                                        <div className='trigger_box flex items-center justify-around'>
                                                                            <img src={flag} alt='flag' />
                                                                            <span className='triggerIcon'>
                                                                                {data?.triggerState === '<' ? (
                                                                                    <LessThansymbol />
                                                                                ) : (
                                                                                    <GreaterThansymbol />
                                                                                )}
                                                                            </span>
                                                                            <span>{data.triggerNumber}</span>
                                                                        </div>

                                                                        <div
                                                                            data-tooltip-id='trigger_tooltips'
                                                                            className='flex trigger_no items-center justify-center  '
                                                                        >
                                                                            {TotalTriggerNo}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        },
                                                    )}
                                                    <Tooltip
                                                        id='trigger_tooltips'
                                                        offset={5}
                                                        place='top'
                                                        className='tooltip-box'
                                                    >
                                                        <p> {t('trigger')} </p>
                                                    </Tooltip>
                                                </div>
                                            ) : (
                                                <div className='flex items-center justify-center w-full h-full'>
                                                    {' '}
                                                    <h6> {t('no_protocol_found')} </h6>{' '}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>

                            <div
                                className={` line_chart_box relative mb-5  ${
                                    isSelectMetric.length > 0 ? 'less_height' : ''
                                }`}
                            >
                                <ProtocolTrendChart
                                    setBackTimeFilter={setBackTimeFilter}
                                    filterFormat={triggerData?.dateRange}
                                    timeFilter={timeFilter}
                                    setTimeFilter={setTimeFilter}
                                    selectedProtocolsList={getFilteredProtocolsList()}
                                    setSelectedTrendProtocol={setSelectedTrendProtocol}
                                    setSelectPreviousProtocol={setSelectPreviousProtocol}
                                    averageProtocolTrigger={triggerData?.averageProtocolTrigger}
                                />
                                <div className='w-full flex items-center justify-center filter_name'>
                                    <span>{t(`timeFilter.${timeFilter}`)}</span>
                                </div>
                            </div>
                            <HorizontalSeparator className='my-5 border-0' />
                            <Metrics
                                evaId={evaId}
                                backTimeFilter={backTimeFilter}
                                timeFilter={timeFilter}
                                setTimeFilter={setTimeFilter}
                                setBackTimeFilter={setBackTimeFilter}
                                metricDrilledDatePayload={metricDrilledDatePayload}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className='no_trend relative trends_protocol'>
                            <Tooltip id='no_trend_tooltip' offset={5} place='right' className='tooltip-box show_arrow'>
                                <p>{t('protocol_frequency')}</p>
                            </Tooltip>
                            <div className='trends_head mb-5 pb-2 flex justify-between items-center'>
                                <h2 className='flex items-center gap-1'>
                                    {t('triggering_frequency')}{' '}
                                    <InfoIcon className='ml-2 cursor-pointer' data-tooltip-id='no_trend_tooltip' />{' '}
                                </h2>
                            </div>

                            <EmptyRisk
                                title={t('no_trends_available')}
                                description={t('deploy_protocol')}
                                buttonName={t('add_protocol')}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Trends;
