import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as SendIcon } from '../../../../../../../../src/assets/icons/Send_Icon.svg';
import { useSubscriptionDetail } from '../../../../../../../contexts/subscription.context';
import { setCheckInput, setInputVal, setIsHandleCreateReport } from '../../../../../../../Redux/SessionsProtocols';
import { RootState, useAppDispatch } from '../../../../../../../Redux/store';
import { SubScriptionType } from '../../../types';

const ChatInput = () => {
    const { t } = useTranslation();
    const subscription = useSubscriptionDetail();
    const isShield = subscription?.productName === SubScriptionType?.SHIELD;
    const dispatch = useAppDispatch();
    const inputVal = useSelector((state: RootState) => state.SessionsProtocols.inputVal);
    const checkInput = useSelector((state: RootState) => state.SessionsProtocols.checkInput);

    const handleClickOrEnter = () => {
        dispatch(setIsHandleCreateReport(true));
    };

    return (
        <div className='chat_input flex items-center justify-between space-x-2'>
            <input
                type='text'
                readOnly={isShield}
                onChange={(e) => {
                    dispatch(setInputVal(e.target.value));
                    dispatch(setCheckInput(true));
                }}
                placeholder={'I want to create a report about...'}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') handleClickOrEnter();
                }}
                value={inputVal}
            />
            <button
                disabled={isShield || !checkInput || !inputVal}
                className={`${isShield ? 'cursor-default' : ''}`}
                onClick={handleClickOrEnter}
            >
                <SendIcon />
            </button>
        </div>
    );
};

export default ChatInput;
