import './style.css';
import { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as OpenDoorIcon } from '../../../../../../src/assets/icons/DoorOpen.svg';
import { ReactComponent as MetricsIcon } from '../../../../../../src/assets/icons/icon-metrics.svg';
import { ReactComponent as CloseEye } from '../../../../../assets/icons/icon-purple-eye-closed.svg';
import { ReactComponent as CloseEyeWhite } from '../../../../../assets/icons/icon-white-eye-closed.svg';
import { ReactComponent as MetricListIcon } from '../../../../../assets/icons/MetricListIcon.svg';
import { setActivityMode, setEvaluateMode, setMetricsMode } from '../../../../../Redux/EvaInfo';
import { RootState } from '../../../../../Redux/store';
import Evaluate from './evaluate/Evaluate.component';
import { clearSvg } from './metrics/MetricCard.component';
import Metrics from './metrics/Metrics.component';
import { Activity } from './publishedPlanet/Activity/Activity';

const useStyles = makeStyles((theme) => ({
    customTooltip: (props: { currentSlide: number }) => ({
        backgroundColor: '#0014FF',
        fontFamily: 'Poppins',
        color: 'white',
        fontSize: '10px',
        fontWeight: 500,
        visibility: props.currentSlide === 0 ? 'hidden' : 'visible',
    }),
}));

const RightSidePanel = ({
    evaId,
    protocol,
    protocolName,
    setEvaluateHint,
    setActionType,
    setNewProtocol,
    showNotification = false,
    setEditUserResponse,
    handleDlClick,
    protocolUpdated,
    currentSlide,
    genMetricProtocol,
}: {
    evaId?: string;
    protocol: { nuance: string; threshold: number }[];
    protocolName: string;
    setEvaluateHint: (hint: string | null) => void;
    setActionType: (type: string | null) => void;
    setNewProtocol: React.Dispatch<
        React.SetStateAction<{
            nuance: string;
            threshold: number;
            oldNuance?: string | undefined;
        }>
    >;
    showNotification?: boolean;
    setEditUserResponse: React.Dispatch<React.SetStateAction<boolean>>;
    handleDlClick: (event: React.MouseEvent<HTMLElement>, index?: number | null) => void;
    protocolUpdated: boolean;
    currentSlide: number;
    genMetricProtocol: (mode: string, title: string, inputValues: { nuance: string; threshold: number }[]) => void;
}) => {
    const classes = useStyles({ currentSlide });
    const { t } = useTranslation();
    const [showCompletePanel, setShowCompletePanel] = useState(false);

    const isMetricsMode = useSelector((state: RootState) => state.EvaInfo.isMetricsMode);
    const isEvaluateMode = useSelector((state: RootState) => state.EvaInfo.isEvaluateMode);
    const isActivityMode = useSelector((state: RootState) => state.EvaInfo.isActivityMode);
    const bluePrintLoading = useSelector((state: RootState) => state.EvaInfo.bluePrintEvaLoading);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isEvaluateMode || isMetricsMode || isActivityMode) setShowCompletePanel(true);
        else {
            setShowCompletePanel(false);
            clearSvg();
        }
    }, [isEvaluateMode, isMetricsMode, isActivityMode]);

    const handleModeChange = (mode: 'activity' | 'evaluate' | 'metrics') => {
        switch (mode) {
            case 'evaluate':
                dispatch(setEvaluateMode(!isEvaluateMode));
                dispatch(setMetricsMode(false));
                dispatch(setActivityMode(false));
                clearSvg();
                break;
            case 'metrics':
                dispatch(setMetricsMode(!isMetricsMode));
                dispatch(setEvaluateMode(false));
                dispatch(setActivityMode(false));
                break;
            case 'activity':
                dispatch(setActivityMode(!isActivityMode));
                dispatch(setMetricsMode(false));
                dispatch(setEvaluateMode(false));
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Tooltip id='metrics_door_icon' offset={5} place='top' className='tooltip-box show_arrow'>
                <p>{showCompletePanel ? t('close') : t('open')}</p>
            </Tooltip>
            <Tooltip id='activity' offset={5} place='top' className='tooltip-box show_arrow'>
                <p>{t('activity')}</p>
            </Tooltip>
            <Tooltip id='evaluate' offset={5} place='top' className='tooltip-box show_arrow'>
                <p>{t('evaluate')}</p>
            </Tooltip>
            <Tooltip id='metrics' offset={5} place='top' className='tooltip-box show_arrow'>
                <p>{t('metrics')}</p>
            </Tooltip>

            <div
                className={` ${
                    bluePrintLoading ? 'disable_overly' : ''
                } right-side-panel-outside-container  h-full no-scrollbar `}
            >
                <div className='h-full flex'>
                    {showCompletePanel && (
                        <div
                            className={clsx(
                                'rsp-expanded-container no-scrollbar',
                                isEvaluateMode ? 'rps-eval-background' : 'rps-default-background',
                                isActivityMode ? 'p-0' : '',
                            )}
                        >
                            {isEvaluateMode && (
                                <Evaluate
                                    protocol={protocol}
                                    protocolName={protocolName}
                                    setEvaluateHint={setEvaluateHint}
                                    setActionType={setActionType}
                                    setNewProtocol={setNewProtocol}
                                    setEditUserResponse={setEditUserResponse}
                                />
                            )}
                            {isMetricsMode && (
                                <Metrics
                                    handleDlClick={handleDlClick}
                                    protocolUpdated={protocolUpdated}
                                    genMetricProtocol={genMetricProtocol}
                                />
                            )}
                            {isActivityMode && (
                                <>
                                    <div className='blueprint_activity h-full main_activity'>
                                        <Activity />
                                    </div>
                                </>
                            )}
                        </div>
                    )}
      
                    <div className={clsx('rsp-menu', showCompletePanel ? 'rsp-menu-expanded' : 'rsp-menu-no-expand')}>
                        <button
                            data-tooltip-id='metrics_door_icon'
                            onClick={() => handleModeChange('activity')}
                            className='door_btn flex justify-center items-center'
                        >
                            <OpenDoorIcon
                                className='w-3 h-3'
                                fill={isEvaluateMode ? 'rgba(96, 244, 200, 0.75)' : 'rgba(111, 191, 225, 0.75)'}
                            />
                        </button>

                        <div className='horizontal_line' />

                        <div
                            data-tooltip-id='activity'
                            className={clsx(
                                'rsp-icon',
                                currentSlide === 1 && 'cursor-pointer',
                                isActivityMode && 'rsp-icon-selected',
                            )}
                            onClick={() => handleModeChange('activity')}
                        >
                            <MetricListIcon
                                className='w-3 h-3'
                                fill={isEvaluateMode ? 'rgba(96, 244, 200, 0.75)' : 'rgba(111, 191, 225, 0.75)'}
                            />
                        </div>

                        <div
                            data-tooltip-id='evaluate'
                            className={clsx('rsp-icon', currentSlide === 1 && 'cursor-pointer')}
                            onClick={() => handleModeChange('evaluate')}
                        >
                            {isEvaluateMode ? (
                                <div className='eva-test-eye-icon-active'>
                                    <CloseEyeWhite width={15} height={15} fill='rgba(96, 244, 200, 0.75)' />
                                </div>
                            ) : (
                                <CloseEye width={15} height={15} fill='rgba(111, 191, 225, 0.75)' />
                            )}
                        </div>

                        <div
                            data-tooltip-id='metrics'
                            className={clsx(
                                'rsp-icon',
                                currentSlide === 1 && 'cursor-pointer',
                                isMetricsMode && 'rsp-icon-selected',
                            )}
                            onClick={() => handleModeChange('metrics')}
                        >
                            {showNotification && <div className='metric-notification' />}
                            <MetricsIcon
                                className='w-3 h-3'
                                fill={isEvaluateMode ? 'rgba(96, 244, 200, 0.75)' : 'rgba(111, 191, 225, 0.75)'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RightSidePanel;
