import { useState, useEffect, useRef } from 'react';

import './style.css';
import clsx from 'clsx';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { OversightData } from '../../store/OrganisationStore';
import { months, OversightTimeRange } from '../../utils/constants';
import { Select } from '../basic/Select.component';
import BlueprintState from './BlueprintState.component';
import {
    displayStateBasedOnTimeRange,
    generate24Hours,
    generate30Days,
    generate3Months,
    generate7Days,
    generateDefaultDropdownValue,
    generateDropdownOptions,
    generateYear,
    getDateBasedOnTimeRange,
    getDifferentTimeRangeMinWidth,
    getSelectedDateClass,
    monthsMap,
    quarterStartMonths,
} from './GenerateDates';

const HorizontalDatePicker = ({ oversightData }: { oversightData: OversightData[] }) => {
    const { t } = useTranslation();
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState<Date | null>(today);
    const [currentMonth, setCurrentMonth] = useState(today.getMonth());
    const [currentYear, setCurrentYear] = useState(today.getFullYear());
    const [currentDay, setCurrentDay] = useState(today.getDate());
    const [currentWeek, setCurrentWeek] = useState<string | undefined>();
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>(OversightTimeRange[30]);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState(
        generateDefaultDropdownValue(selectedTimeRange, currentYear, currentMonth, currentDay),
    );
    const [itemWidth, setItemWidth] = useState(100);
    const hrLineRef = useRef<HTMLDivElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    // Generate dates dynamically based on month selection
    const generateDates = (year: number, month: number, day: number, week: string | undefined, timeRange: string) => {
        switch (timeRange) {
            case OversightTimeRange[24]:
                return generate24Hours(year, month, day);
            case OversightTimeRange[7]:
                if (!week) return generate30Days(year, month);
                return generate7Days(year, month, week);
            case OversightTimeRange[30]:
                return generate30Days(year, month);
            case OversightTimeRange[90]:
                return generate3Months(year, month);
            case OversightTimeRange[365]:
                return generateYear(year);
            default:
                return generate30Days(year, month);
        }
    };

    const [dates, setDates] = useState(() =>
        generateDates(currentYear, currentMonth, currentDay, currentWeek, selectedTimeRange),
    );

    useEffect(() => {
        setDates(generateDates(currentYear, currentMonth, currentDay, currentWeek, selectedTimeRange));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMonth, currentYear, selectedTimeRange, selectedDate, currentDay, currentWeek]);

    useEffect(() => {
        setCurrentWeek(generateDefaultDropdownValue(selectedTimeRange, currentYear, currentMonth, currentDay));
    }, [currentYear, currentMonth, currentDay, selectedTimeRange]);

    //horizontal line width update when scroll
    useEffect(() => {
        if (!scrollContainerRef.current || !hrLineRef.current) return;

        const scrollContainer = scrollContainerRef.current;

        const dateColorMap = new Map<string, string>();

        // **Initialize all dates as gray**
        dates.forEach((date) => {
            dateColorMap.set(date.toDateString(), '#D3D3D3');
        });

        // **Assign gradient colors for oversight periods**
        dates.forEach((date) => {
            const oversightData = getOversightState(date);
            if (oversightData.length > 0) {
                dateColorMap.set(date.toDateString(), '#FF5BD1'); // Active state color
            }
        });

        // **Convert Map to Gradient String**
        const gradientColors = dates.map((date) => dateColorMap.get(date.toDateString()) || '#D3D3D3');

        if (hrLineRef.current) {
            hrLineRef.current.style.border = '1px solid transparent';
            hrLineRef.current.style.borderImageSource = `linear-gradient(90deg, ${gradientColors.join(', ')})`;
            hrLineRef.current.style.borderImageSlice = '1';
        }

        // Update width based on scroll
        const updateHrLineWidth = () => {
            const itemWidth = scrollContainer.scrollWidth / dates.length;
            if (hrLineRef.current) hrLineRef.current.style.width = `${itemWidth * dates.length}px`;
        };

        updateHrLineWidth();
        window.addEventListener('resize', updateHrLineWidth);
        scrollContainer.addEventListener('scroll', updateHrLineWidth);

        return () => {
            window.removeEventListener('resize', updateHrLineWidth);
            scrollContainer.removeEventListener('scroll', updateHrLineWidth);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dates]);

    const handleScroll = (direction: string, timeRange: string) => {
        setSelectedDate(null);
        const scrollFunctions: Record<string, () => void> = {
            [OversightTimeRange[24]]: () => scrollDay(direction),
            [OversightTimeRange[7]]: () => scrollWeek(direction),
            [OversightTimeRange[30]]: () => scrollMonth(direction),
            [OversightTimeRange[90]]: () => scrollQuarter(direction),
            [OversightTimeRange[365]]: () => scrollYear(direction),
        };
        (scrollFunctions[timeRange] || scrollMonth(direction))();
    };

    const updateDate = (year: number, month: number, day: number) => {
        setCurrentYear(year);
        setCurrentMonth(month);
        setCurrentDay(day);
    };

    const scrollYear = (direction: string) => {
        setCurrentYear((prevYear) => prevYear + (direction === 'left' ? -1 : 1));
    };

    const scrollDay = (direction: string) => {
        const currentDate = new Date(currentYear, currentMonth, currentDay);
        currentDate.setDate(currentDate.getDate() + (direction === 'left' ? -1 : 1));
        updateDate(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    };

    const scrollMonth = (direction: string) => {
        let newMonth = currentMonth + (direction === 'left' ? -1 : 1);
        let newYear = currentYear;

        if (newMonth < 0) {
            newMonth = 11;
            newYear--;
        } else if (newMonth > 11) {
            newMonth = 0;
            newYear++;
        }
        updateDate(newYear, newMonth, currentDay);
    };

    const scrollWeek = (direction: string) => {
        const currentDate = new Date(currentYear, currentMonth, currentDay);
        currentDate.setDate(currentDate.getDate() + (direction === 'left' ? -7 : 7));
        updateDate(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    };

    const scrollQuarter = (direction: string) => {
        let newMonth = currentMonth + (direction === 'left' ? -3 : 3);
        let newYear = currentYear;

        if (newMonth < 0) {
            newMonth += 12;
            newYear--;
        } else if (newMonth > 11) {
            newMonth -= 12;
            newYear++;
        }
        updateDate(newYear, newMonth, currentDay);
    };

    const handleChange = (timeRange: string, value: string) => {
        const handlers: Record<string, (val: string) => void> = {
            [OversightTimeRange[24]]: handleDayChange,
            [OversightTimeRange[7]]: handleWeekChange,
            [OversightTimeRange[30]]: handleMonthChange,
            [OversightTimeRange[90]]: handleQuarterChange,
            [OversightTimeRange[365]]: (val) => handleYearChange(parseInt(val, 10)),
        };

        return (handlers[timeRange] || handleMonthChange)(value);
    };

    const handleMonthChange = (monthWithYear: string) => {
        setSelectedDate(null);
        const [monthName, year] = monthWithYear.split(' ');
        const month = months.indexOf(monthName);
        setCurrentMonth(month);
        setCurrentYear(parseInt(year, 10));
    };

    const handleYearChange = (year: number) => {
        setSelectedDate(null);
        setCurrentYear(year);
    };

    const handleDayChange = (date: string) => {
        setSelectedDate(null);
        const parsedDate = moment(date, 'MMM D, YYYY');
        setCurrentDay(parsedDate.date());
        setCurrentMonth(parsedDate.month());
        setCurrentYear(parsedDate.year());
    };

    const handleWeekChange = (week: string) => {
        const match = week.match(/(\d+)-\d+ (\w+), (\d{4})/);
        if (!match) return;

        const [, startDay, monthStr, year] = match;
        const month = monthsMap[monthStr];
        if (month === undefined) return;

        setCurrentYear(parseInt(year, 10));
        setCurrentMonth(month);
        setCurrentDay(parseInt(startDay, 10));
    };

    const handleQuarterChange = (quarter: string) => {
        const match = quarter.match(/(\d{4})/);
        if (!match) return;

        const year = parseInt(match[1], 10);
        const startMonth = Object.entries(quarterStartMonths).find(([label]) => quarter.includes(label))?.[1];

        if (startMonth !== undefined) {
            setCurrentMonth(startMonth);
            setCurrentYear(year);
        }
    };

    //go to today's date
    const goToToday = () => {
        setSelectedDate(today);
        setCurrentMonth(today.getMonth());
        setCurrentYear(today.getFullYear());
        setCurrentDay(today.getDate());
        const todayIndex = dates.findIndex(
            (date) =>
                date.getFullYear() === today.getFullYear() &&
                date.getMonth() === today.getMonth() &&
                date.getDate() === today.getDate(),
        );

        if (todayIndex !== -1 && scrollContainerRef.current) {
            const scrollPosition = todayIndex * itemWidth; // Calculate position

            // Smooth scroll to today's date
            setTimeout(() => {
                scrollContainerRef.current?.scrollTo({ left: scrollPosition, behavior: 'smooth' });
            }, 100);
        }
    };

    const getOversightState = (date: Date): OversightData[] => {
        if (!oversightData.length) return [];

        //@ts-ignore
        return oversightData.flatMap((eva) => {
            const createdAt = new Date(eva.createdAt);
            createdAt.setHours(0, 0, 0, 0); // Normalize

            const endDate = eva.endedAt ? new Date(eva.endedAt) : null;
            if (endDate) endDate.setHours(0, 0, 0, 0); // Normalize

            let latestSnapshotStartDate: Date | null = null;
            const results: OversightData[] = [];

            // **Find the earliest snapshot start date**
            for (const { startDate } of eva.snapshotData) {
                const snapshotDate = new Date(startDate);
                snapshotDate.setHours(0, 0, 0, 0);

                if (!latestSnapshotStartDate || snapshotDate < latestSnapshotStartDate) {
                    latestSnapshotStartDate = snapshotDate;
                }
            }

            // **Check if EVA was created on this date**
            if (createdAt.toDateString() === date.toDateString()) {
                results.push({
                    ...eva,
                    type: 'active',
                    size: 'create',
                    tooltipName: 'Initial State',
                    date: new Date(eva.createdAt),
                    reportData: eva.reportData,
                });
            }

            // **Check if it's a snapshot on the given date**
            const snapshotMatch = eva.snapshotData.find(
                ({ startDate }) => new Date(startDate).toDateString() === date.toDateString(),
            );

            if (snapshotMatch) {
                results.push({
                    ...eva,
                    type: 'updated',
                    size: 'snapshot',
                    tooltipName: snapshotMatch.name,
                    date: new Date(snapshotMatch.startDate),
                    reportData: snapshotMatch.report,
                });
            }

            // **Check if it's triggered on the given date**
            const triggerMatch = eva.triggeredData.find(
                ({ triggeredDate }) => new Date(triggeredDate).toDateString() === date.toDateString(),
            );

            if (triggerMatch) {
                let size: 'small' | 'medium' | 'large' = 'small';
                if (triggerMatch.count >= 200 && triggerMatch.count < 500) size = 'medium';
                else if (triggerMatch.count >= 500) size = 'large';
                results.push({
                    ...eva,
                    type: latestSnapshotStartDate !== null && date >= latestSnapshotStartDate ? 'updated' : 'active',
                    size: size,
                    tooltipName: 'Checkpoint',
                    date: new Date(triggerMatch.triggeredDate),
                });
            }
            // **Handle active state before snapshot startDate, else updated**
            if (createdAt <= date && (!endDate ? date < today : date <= endDate)) {
                if (latestSnapshotStartDate !== null && date >= latestSnapshotStartDate) {
                    results.push({
                        ...eva,
                        type: 'updated',
                        size: 'small',
                        tooltipName: 'Checkpoint',
                        date: date,
                    });
                } else {
                    results.push({
                        ...eva,
                        type: 'active',
                        size: 'small',
                        tooltipName: 'Checkpoint',
                        date: date,
                    });
                }
            }

            return results;
        });
    };

    const checkIfPublishedEvaExists = (): OversightData | undefined => {
        const publishedEva = oversightData.find((eva) => eva.isPublished);
        if (!publishedEva) return undefined;

        return {
            ...publishedEva,
            type: publishedEva.snapshotData.length > 0 ? 'updated' : 'active',
            size: publishedEva.snapshotData.length > 0 ? 'snapshot' : 'today',
            tooltipName: 'Current State',
            date: new Date(),
        };
    };

    //dynamic scroll of dates
    useEffect(() => {
        const handleScroll = () => {
            if (!scrollContainerRef.current) return;
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;

            let firstVisibleIndex = Math.floor(scrollLeft / itemWidth);

            // Ensure index is within range
            if (firstVisibleIndex < 0) firstVisibleIndex = 0;
            if (firstVisibleIndex >= dates.length) firstVisibleIndex = dates.length - 1;

            const visibleDate = dates[firstVisibleIndex] || dates[dates.length - 1];

            if (scrollLeft <= 0) {
                // Prepend dates when scrolled left
                setDates((prev) => {
                    const firstDate = prev[0];
                    let year = firstDate.getFullYear();
                    let month = firstDate.getMonth();
                    let day = firstDate.getDate();
                    if (selectedTimeRange === OversightTimeRange[365] && month === 0) year = year - 1;
                    else if (selectedTimeRange === OversightTimeRange[24]) {
                        // Create a new Date object and move back one day
                        const prevDate = new Date(year, month, day - 1);

                        // Extract updated year, month, and day
                        year = prevDate.getFullYear();
                        month = prevDate.getMonth();
                        day = prevDate.getDate();
                    } else {
                        month = month - 1;
                    }
                    const week = generateDefaultDropdownValue(selectedTimeRange, year, month, day);

                    // Generate new dates
                    const newDates = generateDates(year, month, day, week, selectedTimeRange);

                    // Prevent duplicates using a Set
                    const uniqueDates = Array.from(new Set([...newDates, ...prev].map((d) => d.toISOString()))).map(
                        (d) => new Date(d),
                    );

                    return uniqueDates;
                });

                // Preserve scroll position to avoid jump
                setTimeout(() => {
                    if (scrollContainerRef.current) {
                        scrollContainerRef.current.scrollLeft += 200; // Adjust as needed
                    }
                }, 0);
            } else if (scrollLeft + clientWidth >= scrollWidth - 50) {
                setDates((prev) => {
                    const lastDate = prev[prev.length - 1];
                    let newYear = lastDate.getFullYear();
                    let newDay = lastDate.getDate();
                    let newMonth = lastDate.getMonth();

                    if (selectedTimeRange === OversightTimeRange[365] && newMonth === 11) newYear = newYear + 1;
                    else if (selectedTimeRange === OversightTimeRange[24]) {
                        // Create a new Date object and move forward one day
                        const nextDate = new Date(newYear, newMonth, newDay + 1);

                        // Extract updated year, month, and day
                        newYear = nextDate.getFullYear();
                        newMonth = nextDate.getMonth();
                        newDay = nextDate.getDate();
                    } else {
                        newMonth = newMonth + 1;
                    }

                    // Generate new dates
                    const newDates = generateDates(
                        newYear,
                        newMonth,
                        newDay,
                        generateDefaultDropdownValue(selectedTimeRange, newYear, newMonth, newDay),
                        selectedTimeRange,
                    );

                    // Merge existing and new dates while ensuring uniqueness
                    const uniqueDates = Array.from(new Set([...prev, ...newDates].map((d) => d.toISOString()))).map(
                        (d) => new Date(d),
                    );

                    return uniqueDates;
                });
            }

            // **Update Selected Dropdown Value**
            if (visibleDate) {
                setSelectedDropdownValue(
                    generateDefaultDropdownValue(
                        selectedTimeRange,
                        visibleDate.getFullYear(),
                        visibleDate.getMonth(),
                        visibleDate.getDate(),
                    ),
                );
            }
        };

        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dates, selectedTimeRange]);

    useEffect(() => {
        if (scrollContainerRef.current) {
            const firstItem = scrollContainerRef.current.querySelector('.date'); // Adjust selector
            if (firstItem) {
                setItemWidth((firstItem as HTMLElement).offsetWidth);
            }
        }
    }, [dates]);

    useEffect(() => {
        //reset scroll position
        setTimeout(() => {
            if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' });
            }
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTimeRange]);

    //update dropdown value based on selected date
    useEffect(() => {
        if (dates.length > 0) {
            const firstDate = dates[0];
            setSelectedDropdownValue(
                generateDefaultDropdownValue(
                    selectedTimeRange,
                    firstDate.getFullYear(),
                    firstDate.getMonth(),
                    firstDate.getDate(),
                ),
            );
        }
    }, [dates, selectedTimeRange]);

    return (
        <div className='calendar-container'>
            <Tooltip id='initiate_blueprint' offset={5} place='top' className='tooltip-box show_arrow'>
                <p> {t('initiateBlueprint')} </p>
            </Tooltip>
            <div className='flex justify-between items-center'>
                <div className='flex items-center mb-4 space-x-4'>
                    <Select
                        entries={generateDropdownOptions(selectedTimeRange, currentYear, currentMonth)}
                        value={selectedDropdownValue}
                        onChange={(value) => handleChange(selectedTimeRange, value)}
                        className='w-40'
                        placeholder='Select Month'
                        marginTop={45}
                        backgroundColor='transparent'
                    />
                    <button className='arrow' onClick={() => handleScroll('left', selectedTimeRange)}>
                        {'<'}
                    </button>
                    <button className='arrow' onClick={() => handleScroll('right', selectedTimeRange)}>
                        {'>'}
                    </button>
                    <button
                        className={clsx(
                            'today',
                            selectedDate?.toDateString() === today.toDateString() && 'today-selected',
                        )}
                        onClick={goToToday}
                    >
                        Today
                    </button>
                </div>
                <div className='date-range-container'>
                    {Object.values(OversightTimeRange).map((range, index) => (
                        <p
                            key={index}
                            className={clsx(
                                'date-range-item',
                                selectedTimeRange === range && 'date-range-item-selected',
                            )}
                            onClick={() => setSelectedTimeRange(range)}
                        >
                            {range}
                        </p>
                    ))}
                </div>
            </div>
            <div className='date-body scrollbar' ref={scrollContainerRef}>
                <div className='hr-line' ref={hrLineRef} />
                <div className='w-full flex flex-col space-y-4 date-container'>
                    <div className='flex space-x-2'>
                        {dates.map((date) => (
                            <div className='w-full flex flex-col items-center' key={date.toISOString()}>
                                <button
                                    className={clsx(
                                        'date',
                                        getSelectedDateClass(date, selectedTimeRange, selectedDate),
                                        date.toDateString() === today.toDateString() && 'today-date',
                                        getDifferentTimeRangeMinWidth(selectedTimeRange),
                                    )}
                                    onClick={() => setSelectedDate(date)}
                                >
                                    {getDateBasedOnTimeRange(date, selectedTimeRange)}
                                </button>

                                <div className='blueprint-state-container'>
                                    {displayStateBasedOnTimeRange(selectedTimeRange, date) ? (
                                        checkIfPublishedEvaExists() ? (
                                            <BlueprintState
                                                key={checkIfPublishedEvaExists()?.evaId}
                                                oversightItem={checkIfPublishedEvaExists()}
                                                type={checkIfPublishedEvaExists()?.type || 'active'}
                                                size={checkIfPublishedEvaExists()?.size || 'small'}
                                                title='Current State'
                                            />
                                        ) : (
                                            <div className='create' data-tooltip-id='initiate_blueprint'>
                                                <p>+</p>
                                            </div>
                                        )
                                    ) : (
                                        getOversightState(date).length > 0 && (
                                            <div className='multiple-states group relative'>
                                                {getOversightState(date).map((oversightItem, index) => (
                                                    <BlueprintState
                                                        key={oversightItem.evaId + index}
                                                        oversightItem={oversightItem}
                                                        type={oversightItem.type || 'active'}
                                                        size={oversightItem.size || 'small'}
                                                        className='state-item'
                                                        style={{ '--index': index } as React.CSSProperties}
                                                        title={oversightItem.tooltipName || 'State'}
                                                    />
                                                ))}
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HorizontalDatePicker;
