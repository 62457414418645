import { useState } from 'react';

import Popover from '@mui/material/Popover';
import clsx from 'clsx';

import { OversightData } from '../../store/OrganisationStore';
import MetricType from '../organisation/copilotOrganisation/eva/components/metrics/MetricType.component';
import { protocolTypeIcon } from '../organisation/copilotOrganisation/eva/components/ProtocolTypeList';

const BlueprintState = ({
    oversightItem,
    type,
    size,
    className,
    style,
    title,
}: {
    oversightItem?: OversightData;
    size: 'small' | 'medium' | 'large' | 'today' | 'create' | 'snapshot';
    type: 'active' | 'updated';
    className?: string;
    style?: React.CSSProperties;
    title: string;
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    //when hover get the metric calculation details
    return (
        <div className={clsx('state-container', className)} style={style}>
            <div
                className={clsx('state', `trigger-${size}`, `${type}`)}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            />
            <Popover
                id='mouse-over-popover'
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                PaperProps={{
                    sx: {
                        borderRadius: '15px',
                        boxShadow: '0px 2px 4px 0px #0000001A',
                        backgroundColor: '#FFFFFF !important',
                        padding: '1rem',
                        marginTop: '1rem',
                        marginLeft: '-9rem',
                    },
                }}
            >
                <div className='state-content'>
                    <div className='flex justify-between text-darker text-opacity-75 items-center'>
                        <p className='text-sm font-medium'>{title}</p>
                        {oversightItem?.date && (
                            <p className='italic font-normal text-xs'>{new Date(oversightItem.date).toDateString()}</p>
                        )}
                    </div>
                    <div className='mt-4 space-y-4'>
                        {/* <div className='text-darker text-opacity-50 text-xs font-normal'>
                            <p>Alignment Rate: 78% (-12%)</p>
                        </div> */}
                        <div className='font-normal'>
                            <p className='text-darker text-opacity-65 text-sm'>
                                Protocol Distribution ({oversightItem?.totalProtocolCount})
                            </p>
                            <div className='text-xs text-darker text-opacity-50 mt-2 space-y-1'>
                                {oversightItem?.protocolCategoryCount.map((category) => (
                                    <div key={category.category} className='flex space-x-2'>
                                        <img src={protocolTypeIcon(category.category)} className='w-5 h-5' alt='hint' />
                                        <p>
                                            {category.category} ({category.count})
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {oversightItem?.metrics && (
                            <div className='font-normal'>
                                <p className='text-darker text-opacity-65 text-sm'>Key Metric Changes</p>
                                <div className='text-xs text-darker text-opacity-50 mt-2 space-y-1'>
                                    {oversightItem?.metrics.map((metric) => (
                                        <div key={metric.name} className='flex space-x-2'>
                                            <div>
                                                <MetricType type={metric.format} />
                                            </div>
                                            <p className='capitalize'>{metric.name}</p>
                                            <div className='metric-change positive'>+22%</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {oversightItem?.reportData && oversightItem.reportData.length > 0 && (
                            <div className='font-normal scrollbar report-container'>
                                <p className='text-darker text-opacity-65 text-sm'>Reports</p>
                                <div className='text-xs text-darker text-opacity-50 mt-2 space-y-1'>
                                    {oversightItem?.reportData.map((report) => (
                                        <div key={report.report_name} className='report-card'>
                                            <p>{report.report_name}</p>
                                            <p>{new Date(report.created_at).toDateString()}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Popover>
        </div>
    );
};

export default BlueprintState;
