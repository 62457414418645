import { useState } from 'react';

import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as ArchiveIcon } from '../../../../../../src/assets/icons/archive_btn.svg';
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/icons/close_btn.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../src/assets/icons/delete_btn.svg';
import miniOrbit from '../../../../../../src/assets/icons/mini_OrbitIocn.svg';
import miniOrbitHover from '../../../../../../src/assets/icons/mini_OrbitIocnHover.svg';
import miniOrbitwhite from '../../../../../../src/assets/icons/mini_Orbitwhite.svg';
import { ReactComponent as RestartIcon } from '../../../../../../src/assets/icons/restart_btn.svg';
import { ReactComponent as Check } from '../../../../../assets/icons/check.svg';
import { useSubscriptionDetail } from '../../../../../contexts/subscription.context';
import { setRemoveArchiveEvaIdConfirmation } from '../../../../../Redux/EvaInfo';
import { RootState } from '../../../../../Redux/store';
import { Button } from '../../../../basic/Button.component';
import { StarIcon } from '../../../../basic/StarIcon.component';
import { EvaType, MetaMarkerData, SubScriptionType } from '../types';

interface CreateEvaModelProps {
    currentSlide: number;
    isArchived: boolean;
    isTestMode: boolean;
    setIsTestMode: React.Dispatch<React.SetStateAction<boolean>>;
    publishedAt: string | null;
    archive: boolean;
    isUserAdmin: boolean | undefined;
    handleArchiveEva: () => void;
    handleUnarchiveEva: () => void;
    setIsOpenRestartModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsOpenPublishModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleSubmit: (saveType: EvaType, data?: MetaMarkerData) => Promise<void>;
    setEvaPublished: React.Dispatch<React.SetStateAction<boolean>>;
    isPublishEnable: boolean | number;
    isEvaPublished: boolean;
    handleClose: () => void;
    isPublished: boolean;
    setLabelShow: React.Dispatch<React.SetStateAction<boolean>>;
    labelShow: boolean;
    setEditUserResponse: React.Dispatch<React.SetStateAction<boolean>>;
}

const EvaHeader: React.FC<CreateEvaModelProps> = ({
    currentSlide,
    isArchived,
    isTestMode,
    setIsTestMode,
    publishedAt,
    archive,
    isUserAdmin,
    handleArchiveEva,
    handleUnarchiveEva,
    setIsOpenRestartModal,
    setIsOpenDeleteModal,
    setIsOpenPublishModal,
    handleSubmit,
    setEvaPublished,
    isPublishEnable,
    isEvaPublished,
    handleClose,
    setLabelShow,
    labelShow,
    isPublished,
    setEditUserResponse,
}) => {
    const handleTestModeClick = (isTestModeValue: boolean) => {
        setIsTestMode(isTestModeValue);
        setEditUserResponse(false);
    };
    const evaId = useSelector((state: RootState) => state.EvaInfo.evaId);
    const archiveEvaId = useSelector((state: RootState) => state.EvaInfo.archiveEvaId);
    const updateEvaLoading = useSelector((state: RootState) => state.EvaInfo.updateEvaLoading);

    const dispatch = useDispatch();

    const RemoveArchiveIdConfirm = () => {
        if (archiveEvaId == null) {
            handleClose();
        } else {
            dispatch(setRemoveArchiveEvaIdConfirmation(true));
        }
    };

    const { t } = useTranslation();

    const { id: organisationId } = useParams<{ id: string }>();

    const subscription = useSubscriptionDetail();
    const [OrbitHover, setOrbitHover] = useState<boolean>(false);

    const shieldSubscription = subscription.productName === SubScriptionType.SHIELD;

    return (
        <div className='buttons-eva eva_header flex   '>
            <Tooltip id='orbit_eva_head' offset={5} place='top' className='tooltip-box show_arrow'>
                <p> {labelShow ? t('hide_labels') : t('show_labels')} </p>
            </Tooltip>
            <Tooltip id='archive_head' offset={5} place='top' className='tooltip-box show_arrow'>
                <p>{t('archive')}</p>
            </Tooltip>
            <Tooltip id='restart_head' offset={5} place='top' className='tooltip-box show_arrow'>
                <p> {t('restart')} </p>
            </Tooltip>
            <Tooltip id='unarchive_head' offset={5} place='top' className='tooltip-box show_arrow'>
                <p> {t('unarchive')}</p>
            </Tooltip>
            <Tooltip id='delete_head' offset={5} place='top' className='tooltip-box show_arrow'>
                <p> {t('btnNames.delete')}</p>
            </Tooltip>
            <Tooltip id='publish_head' offset={5} place='top' className='tooltip-box show_arrow'>
                <p>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: t('visibility'),
                        }}
                    />
                </p>
            </Tooltip>

            <div
                className={
                    currentSlide === 0
                        ? 'relative flex w-full h-fit align-center'
                        : 'flex w-full align-center right_side_button '
                }
            >
                <Button
                    data-tooltip-id='orbit_eva_head'
                    size='oval'
                    className='flex space-x-2 relative items-center restart_btn head_btn orbit_eva_btn'
                    variant='evaRestart'
                    disabled={!isUserAdmin}
                    onClick={() => {
                        setLabelShow(!labelShow);
                    }}
                    onMouseEnter={() => setOrbitHover(true)}
                    onMouseLeave={() => setOrbitHover(false)}
                >
                    {OrbitHover ? (
                        <img
                            src={labelShow ? miniOrbitHover : miniOrbitwhite}
                            alt=''
                            className={`${labelShow ? 'orbit_hover' : ''} cursor-pointer`}
                        />
                    ) : (
                        <img
                            src={labelShow ? miniOrbit : miniOrbitwhite}
                            alt=''
                            className={`${labelShow ? 'orbit_hover' : ''} cursor-pointer`}
                        />
                    )}
                </Button>
                <>
                    {!publishedAt && !archive && (
                        <Button
                            data-tooltip-id='restart_head'
                            size='oval'
                            className='flex space-x-2 relative items-center restart_btn head_btn'
                            variant='evaRestart'
                            disabled={!isUserAdmin}
                            onClick={() => setIsOpenRestartModal(true)}
                        >
                            <RestartIcon />
                        </Button>
                    )}
                </>

                {!archive ? (
                    <Button
                        data-tooltip-id='archive_head'
                        size='oval'
                        className='flex space-x-2 relative items-center head_btn isarchive_btn '
                        variant={shieldSubscription ? 'outlinedArchiveNoHover' : 'outlinedArchive'}
                        onClick={shieldSubscription ? noop : handleArchiveEva}
                        disabled={!isUserAdmin}
                    >
                        {shieldSubscription ? (
                            <Link to={`/organisation/${organisationId}/change-tier`}>
                                <StarIcon isChangePlusIcon={true} isHover={true} />
                            </Link>
                        ) : (
                            <ArchiveIcon />
                        )}
                    </Button>
                ) : (
                    <Button
                        data-tooltip-id='unarchive_head'
                        className='flex space-x-2 relative isarchive_disable_btn  head_btn items-center'
                        variant='archived'
                        size='oval'
                    >
                        <ArchiveIcon />
                    </Button>
                )}

                <div className='right_side_button '>
                    {evaId && (
                        <>
                            <Button
                                data-tooltip-id='delete_head'
                                className='delete_btn relative cursor-pointer head_btn '
                                disabled={!isUserAdmin}
                                onClick={() => setIsOpenDeleteModal(true)}
                            >
                                <DeleteIcon width={13} height={13} />
                            </Button>
                        </>
                    )}

                    {isEvaPublished ? (
                        <Button
                            data-tooltip-id='publish_head'
                            className='flex space-x-2 ml-4 relative items-center publish_btn publish_btn_disable'
                            size='oval'
                        >
                            {updateEvaLoading ? (
                                <div className='file_loading report_loader' />
                            ) : (
                                <>
                                    <Check fill='#ffff' fillOpacity={1} />
                                    <p className='text-sm text-white'>{t('published')}</p>
                                </>
                            )}
                        </Button>
                    ) : (
                        <Button
                            data-tooltip-id='publish_head'
                            className='publish_btn relative'
                            variant={isPublishEnable && isUserAdmin ? 'evaPublish' : 'fillLightBlueDisabled'}
                            size='oval'
                            onClick={() => {
                                if (archive) {
                                    if (isPublished) {
                                        setIsOpenPublishModal(true);
                                    } else handleUnarchiveEva();
                                } else {
                                    handleSubmit(EvaType.publish);
                                    setEvaPublished(true);
                                }
                            }}
                            disabled={!isPublishEnable || !isUserAdmin}
                        >
                            {t('btnNames.publish')}
                        </Button>
                    )}
                    <Button
                        variant='outlinedCancel'
                        size='small'
                        className='bg-transparent close_btn m-0 eva_close_btn rounded-none h-8'
                        onClick={RemoveArchiveIdConfirm}
                    >
                        <CloseIcon />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EvaHeader;
