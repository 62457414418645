import { useState } from 'react';

const VerticalLine = () => {
    const [hovered, setHovered] = useState(false);
    return (
        <div
            className='vertical-line-container'
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div className='vertical-line'>
                <div className='horizontal-line' />
                <div className='horizontal-line' />
                <div className='horizontal-line' />
                <div className='horizontal-line' />
                <div className='horizontal-line' />
            </div>
            {hovered && (
                <div className='absolute left-0 top-0 h-full flex flex-col justify-between'>
                    {['Excellence 🏆', 'Developing 📊', 'Optimizing 🚀', 'Emerging 🌱'].map((text, index) => (
                        <div key={index} className={`flex items-center label-${index}`}>
                            <span className='mr-2 text-sm category whitespace-nowrap'>{text}</span>
                            <div className='w-0 h-0 border-t-4 border-b-4 border-l-8 border-transparent border-l-gray-600' />
                        </div>
                    ))}
                </div>
            )}

            {/* <div className='flex flex-col items-center container'>
                <button className='create-target-state-dot'>+</button>
                <p className='target-text'>Create Target State</p>
            </div> */}
        </div>
    );
};

export default VerticalLine;
