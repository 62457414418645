import { useEffect, useState } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { ReactComponent as NewEmptyTrend } from '../../../../../../../../src/assets/icons/emptyTrend.svg';
import { ReactComponent as SearchIcon } from '../../../../../../../../src/assets/icons/serachIocn.svg';
import './Activity.css';
import { RootState } from '../../../../../../../Redux/store';
import { ActivityService } from '../../../../../../../services/activity/activity.service';
import Nodata from '../../../../../../Nodata';
import ActivityFilter from './ActivityFilter';
import { ActivityTypeIcon } from './ActivityOption';
import ActivitySort from './ActivitySort';
import { activity } from './activityType';

export const Activity = () => {
    const [sortVal, setSortVal] = useState('');
    const [sortFilterType, setSortFilterType] = useState('');
    const { t } = useTranslation();
    const activityService = new ActivityService();
    const [searchVal, setSearchVal] = useState<string>('');
    const [showInput, setShowInput] = useState<boolean>(false);
    const [activity, setActivity] = useState([]);
    const [page, setPage] = useState(1);
    const [remainingRecords, setRemainingRecords] = useState([]);

    const evaId = useSelector((state: RootState) => state.EvaInfo.evaId);

    const {
        data: activityData,
        refetch,
        isFetching,
    } = useQuery(
        ['getActivity', evaId, sortVal, sortFilterType, searchVal, page],
        async () => {
            if (evaId) {
                const activity = await activityService.getAllActivity(
                    evaId,
                    '',
                    sortVal,
                    sortFilterType,
                    searchVal,
                    String(page),
                );

                return activity;
            }
            return [];
        },
        {
            enabled: !!evaId,
        },
    );
    //when more records in report list
    useEffect(() => {
        if (activityData) {
            if (page === 1) {
                // Set the summary and remaining records for the first page
                setActivity(activityData?.activity?.slice(0, 30));
                setRemainingRecords(activityData?.activity?.slice(30));
            } else {
                // Update remaining records
                setRemainingRecords(activityData?.activity);
                setActivity((prevSummary) => [...prevSummary, ...remainingRecords]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityData, page]);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        const activityList = document.querySelector('.activity_list');
        // Scroll to the top when sortVal is updated
        // Define the scroll handler with debounce
        const handleScroll = debounce(() => {
            if (activityList) {
                const { scrollTop, scrollHeight, clientHeight } = activityList;
                // Check if the user has scrolled near the bottom of the container
                setPage((pre: number) => {
                    if (activityData?.totalPage > pre) {
                        if (Math.abs(scrollTop) + clientHeight > scrollHeight - 10) {
                            return pre + 1;
                        }
                    }
                    return pre;
                });
            }
        }, 0);

        // Attach the scroll event listener
        if (activityList) {
            activityList.addEventListener('scroll', handleScroll);
        }
        // Cleanup: Remove the scroll event listener when the component unmounts
        return () => {
            if (activityList) {
                activityList.removeEventListener('scroll', handleScroll);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityData, page, isFetching]); // Add sortVal to dependencies

    // Debounce utility function to limit the rate of function execution
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function debounce<T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
        let timeoutId: NodeJS.Timeout | null;
        return function (...args: Parameters<T>): void {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    const handleChangeSortType = (name: string) => {
        setSortVal(name);
        const activityList = document.querySelector('.activity_list');
        if (name && activityList) {
            activityList.scrollTop = 0; // Scroll to the top of the element
            setPage(1);
        }
    };

    const handleFilterChange = (filterType: string) => {
        setSortFilterType(filterType);
        const activityList = document.querySelector('.activity_list');
        if (filterType && activityList) {
            activityList.scrollTop = 0; // Scroll to the top of the element
            setPage(1);
        }
    };

    return (
        <>
            <div className='summaries_heading flex justify-between items-center'>
                <div className='flex items-center gap-3'>
                    <h1> {t('activity')} </h1>
                </div>
            </div>

            <div className='activity'>
                <div className='summaries_information panel_top_bar pt-0 flex justify-between'>
                    <div className={` input_box relative ${showInput ? 'showInput' : ''}`}>
                        <SearchIcon className='cursor-pointer' onClick={() => setShowInput(!showInput)} />
                        <input
                            type='text'
                            placeholder='Search'
                            onChange={(e) => {
                                setSearchVal(e.target.value);
                            }}
                        />
                    </div>
                    <div className='flex items-center space-x-4'>
                        <ActivitySort handleSort={handleChangeSortType} />
                        <div className='activity-filter'>
                            <ActivityFilter handleFilterChange={handleFilterChange} />
                        </div>
                    </div>
                </div>

                <div className='activity_list h-full '>
                    <ul className='h-full scrollbar'>
                        {(activityData?.activity?.length === 0 && activityData?.isActivityExist === false) || !evaId ? (
                            <div className='flex no_protocol gap-5 flex-col w-full h-full justify-center items-center'>
                                <Nodata
                                    button={<></>}
                                    title={t('activities.noActivityYet')}
                                    description={<p>{t('activities.actionsInfo')}</p>}
                                    EmptyImage={NewEmptyTrend}
                                />
                            </div>
                        ) : activityData?.activity?.length === 0 && activityData?.isActivityExist === true ? (
                            <p className='no_data'>{activityData?.msg}</p>
                        ) : (
                            activity?.map((item: activity) => {
                                const { support_text, created_at, action } = item;
                                return (
                                    <li key={created_at} className='flex gap-2'>
                                        <div className='activity_icon flex justify-center items-center'>
                                            {action === 'generated' ? (
                                                <img src={ActivityTypeIcon(action)} alt={action} />
                                            ) : (
                                                <span>{ActivityTypeIcon(action)}</span>
                                            )}
                                        </div>
                                        <div className='activity_content'>
                                            <p>{support_text}</p>
                                            <span>
                                                {t('activities.date', {
                                                    date: moment(created_at).format('DD.MM.YY'),
                                                })}
                                            </span>
                                        </div>
                                    </li>
                                );
                            })
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
};
