import GeneratedIcon from '../../../../../../../../src/assets/icons/generated_icon.svg';
import { ActivityOption } from '../../../types';

export const activityOption: ActivityOption[] = [
    {
        name: 'Update',
        type: 'updated',
        icon: String.fromCodePoint(0x270f),
    },
    {
        name: 'Creation',
        type: 'created',
        icon: String.fromCodePoint(0x2795),
    },
    {
        name: 'Report Generated',
        type: 'generated',
        icon: GeneratedIcon,
    },
    {
        name: 'Favorite',
        type: 'set_favorite',
        icon: String.fromCodePoint(0x2b50),
    },
    {
        name: 'Suggestion',
        type: 'suggested',
        icon: String.fromCodePoint(0x1f4ca),
    },
    {
        name: 'Evaluation',
        type: 'evaluated',
        icon: String.fromCodePoint(0x1f441),
    },
    {
        name: 'Save',
        type: 'saved',
        icon: String.fromCodePoint(0x1f4be),
    },
];

export const ActivityTypeIcon = (activityType: string) => {
    const icon = activityOption.find((item) => item.type === activityType);
    return icon?.icon;
};
