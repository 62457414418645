import { useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import Slider from '@mui/material/Slider';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { appInsights } from '../../../../../../AppInsights';
import { ReactComponent as BackIcon } from '../../../../../../assets/icons/icon-arrow-back.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/icons/icon-delete-fill.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/icon-edit-fill.svg';
import { RootState } from '../../../../../../Redux/store';
import { MetricService } from '../../../../../../services/metric/metric.service';
import { OrganisationService } from '../../../../../../services/organisation/organisation.service';
import { Member } from '../../../../../../store/OrganisationStore';
import { Button } from '../../../../../basic/Button.component';
import { Description } from '../../../../../basic/Description.component';
import HorizontalSeparator from '../../../../../basic/HorizontalSeparator';
import { Select } from '../../../../../basic/Select.component';
import { Switch } from '../../../../../basic/Switch.component';
import { Loading } from '../../../../../loading';
import { evaTitleFieldStyle } from '../CreateEva.components';
import { StyledTextField } from '../createEva/StyledTextField';
import EvaDialog from '../EvaDialog.component';
import {
    AssignType,
    categoryList,
    FormatList,
    getAggregationTypeList,
    getCalculationMethodList,
    getFormatList,
    getFrequencyList,
    getTimeFrameList,
    Metric,
    MetricCategoryType,
} from './metricsType';

const useStyles = makeStyles(() => ({
    metricTitle: (props: { error: boolean }) => ({
        backgroundColor: 'transparent',
        width: '100%',
        overflow: 'hidden',
        fontSize: '14px',
        fontStyle: 'italic',
        fontWeight: 400,
        color: 'rgba(111, 191, 225, 1)',
        '& .MuiInput-underline:before, & .MuiInput-underline:after': {
            display: (props) => (props.error ? 'block' : 'none'), // Change based on error state
            borderBottom: (props) => (props.error ? '1px solid #FF5E36' : 'none'), // Apply borderBottom on error
        },
        '& .MuiInput-underline input': {
            textAlign: 'start',
        },
        '& .MuiInputBase-input::placeholder': {
            opacity: 0.75,
            textAlign: 'start',
        },
    }),
}));

const CreateNewMetric = ({
    mode,
    closeMetric,
    metricId,
    updateMetric,
    deleteMetric,
}: {
    mode?: 'create' | 'update';
    closeMetric: () => void;
    metricId?: string;
    updateMetric: (metricId: string, metricData: Partial<Metric>, mode: string) => void;
    deleteMetric: (metricId: string) => void;
}) => {
    const orgService = new OrganisationService();
    const { id: organisationId } = useParams<{ id: string }>();
    const evaId = useSelector((state: RootState) => state.EvaInfo.evaId);

    const { t } = useTranslation();
    const titleRef = useRef<HTMLDivElement>(null);
    const [metric, setMetric] = useState<Metric>({
        name: '',
        description: '',
        category: 'OPI',
        format: '',
        frequency: '',
        method: '',
        timeFrame: '',
        type: '',
        feedback: false,
        assignId: null,
        goldStandard: 0,
        weight: 1,
    });
    const [editTitle, setEditTitle] = useState(true);
    const [count, setCount] = useState<number>(0);
    const [category, setCategory] = useState<MetricCategoryType | undefined>(categoryList.find((c) => c.key === 'OPI'));

    const [assign, setAssign] = useState<AssignType | undefined>();
    const [error, setError] = useState({
        titleError: false,
        descriptionError: false,
        categoryError: false,
        formatError: false,
        frequencyError: false,
        methodError: false,
        timeFrameError: false,
        typeError: false,
        // assignError: false,
        weightError: false,
        goldStandardError: false,
    });
    const [loading, setLoading] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
    const maxLength = 22;
    const classes = useStyles({ error: error.titleError });
    const metricService = new MetricService();

    const {
        data: membersData,
        isFetching,
        isLoading: isLoadingMember,
    } = useQuery(
        ['getActivity', organisationId],
        async () => {
            if (organisationId) {
                const members = await orgService.getMembers(organisationId);

                return members;
            }
            return [];
        },
        {
            enabled: !!organisationId,
        },
    );

    const AssignList: AssignType[] = membersData?.map((member: Member) => ({
        memberId: member?.id,
        name: member?.name,
        profileImg: member?.documents?.length > 0 ? { id: member?.documents?.[0]?.id } : null,
    }));

    const toggleEdit = () => {
        setEditTitle(!editTitle);
        if (titleRef.current) {
            titleRef.current.focus();
        }
    };

    const weightSliderStyle = {
        margin: '0 12px 0 0',
        padding: 0,
        '& .MuiSlider-thumb': {
            color: '#6FBFE1',
            boxShadow: 'none',
            width: 12,
            height: 12,
        },
        '& .MuiSlider-track': {
            color: '#6FBFE1',
        },
        '& .MuiSlider-rail': {
            color: 'rgba(96, 244, 200, 0.1)',
            height: 4,
        },
        '& .MuiSlider-thumb:hover': {
            boxShadow: 'none',
        },
        '& .MuiSlider-mark': {
            display: 'none',
        },
    };

    const handleWeightSliderChange = (_event: Event, newValue: number | number[]) => {
        setMetric((prev) => ({ ...prev, weight: Number(newValue) }));
    };

    const errorChange = (key: string, value: boolean) => {
        setError((prevError) => ({
            ...prevError,
            [key]: value,
        }));
    };
    const handleDisabled = () => {
        if (loading) return true;
        if (
            !metric?.name ||
            !metric.description ||
            !category ||
            !metric.format ||
            !metric.frequency ||
            !metric.method ||
            !metric.timeFrame ||
            !metric.type ||
            // !metric.assignId ||
            !metric.weight ||
            ((metric.format === FormatList.Ratio || metric.format === FormatList.Score) &&
                (metric?.goldStandard === null || metric?.goldStandard === undefined))
        )
            return true;
        if (metric.name === '' && metric.description === '') return true;
        return false;
    };

    const validateMetric = () => {
        const errors = {
            titleError: !metric?.name || metric.name.trim() === '',
            descriptionError: !metric?.description,
            categoryError: !category,
            formatError: !metric?.format,
            frequencyError: !metric?.frequency,
            methodError: !metric?.method,
            timeFrameError: !metric?.timeFrame,
            typeError: !metric?.type,
            // assignError: !metric?.assignId,
            weightError: !metric?.weight,
            goldStandardError:
                (metric?.format === FormatList.Ratio || metric?.format === FormatList.Score) &&
                (metric?.goldStandard === null || metric?.goldStandard === undefined),
        };
        setError(errors);
        return Object.values(errors).some(Boolean);
    };

    const handleCreateOrUpdateMetric = async () => {
        if (!evaId) return;
        if (!metric) return;

        // Validate and set error states
        if (validateMetric()) {
            setLoading(false);
            return; // Exit if validation fails
        }

        setLoading(true);
        try {
            let newMetricId = '';
            const isNameExist = await metricService.isMetricNameUnique(metric.name, evaId, metricId);
            if (isNameExist) {
                errorChange('titleError', true);
                setLoading(false);
                return;
            }
            const metricData = {
                ...metric,
                category: category?.key || '',
                isEnabled: true,
                goldStandard:
                    metric.format === FormatList.Ratio || metric.format === FormatList.Score
                        ? metric.goldStandard
                        : null,
            };
            if (mode === 'update' && metricId) {
                await metricService.updateMetric(metricData);
            } else {
                const newMetric = await metricService.createMetric(metricData, evaId);
                if (newMetric) newMetricId = newMetric.id;
            }

            updateMetric(mode === 'update' ? metricId || newMetricId : newMetricId, metricData, mode || 'create');
            setLoading(false);
            closeMetric();
        } catch (err) {
            console.error(err);
            // Optionally set an error state to display to the user
            setLoading(false);
            if (err instanceof Error) {
                Sentry.captureException(err);
            }
        }
    };

    const { isLoading } = useQuery(
        ['getMetricDetails', metricId],
        async () => {
            try {
                const data = await metricService.getMetricDetails(metricId as string);
                setMetric(data);
                setCategory(categoryList.find((item) => item.key === data.category));
                setAssign(AssignList.find((item) => item.memberId === data.assignId));
                return data; // Ensure to return the data
            } catch (e) {
                if (e instanceof Error) {
                    Sentry.captureException(e);
                    appInsights.trackException({ error: e });
                }
                return []; // Return an empty array in case of error
            }
        },
        { enabled: !!metricId && mode === 'update' },
    );

    const handleGoldStandardChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        // Allow empty string to enable clearing input
        if (value === '') {
            setMetric((prev) => ({
                ...prev,
                goldStandard: null,
            }));
            return;
        }

        // Ensure the value is a valid number
        let numericValue = parseInt(value, 10);

        if (numericValue > 100) numericValue = 100;
        if (numericValue < 0) numericValue = 0;

        setMetric((prev) => ({
            ...prev,
            goldStandard: numericValue,
        }));
    };

    if (isLoading || isLoadingMember || isFetching) return <Loading />;

    return (
        <>
            <EvaDialog
                disabled={false}
                open={isOpenDeleteModal}
                setClose={setIsOpenDeleteModal}
                onConfirm={() => {
                    if (!metricId) return;
                    deleteMetric(metricId);
                    closeMetric();
                }}
                title={t('delete_metric_title', { metricName: metric.name })}
                content={<div>{t('delete_metric_warning')}</div>}
                confirmButtonText={t('deleteMetric')}
                buttonVariant='rejectRed'
            />
            <div className='w-full create-metric-container h-full '>
                <div className='w-full add-metric-title'>
                    <div className='w-full flex items-center space-x-4'>
                        <div className='cursor-pointer delete-metric'>
                            <div>
                                <BackIcon className='w-4 h-4' onClick={closeMetric} fill='rgba(111, 191, 225, 0.75)' />
                            </div>
                        </div>
                        {editTitle ? (
                            <div
                                className='w-full flex items-center'
                                ref={titleRef}
                                onBlur={() => {
                                    if (!metric?.name || metric.name.replaceAll(' ', '') === '') return;
                                    else setEditTitle(false);
                                }}
                                tabIndex={0}
                            >
                                <StyledTextField
                                    id='metric-title'
                                    className={`${classes.metricTitle} w-full`}
                                    font='small'
                                    fontWeight='medium'
                                    placeHolder=''
                                    textFieldPlaceholder={t('metricNamePlaceHolder')}
                                    value={metric?.name}
                                    setValue={(value) => setMetric((prev) => ({ ...prev, name: value }))}
                                    maxLength={maxLength}
                                    style={evaTitleFieldStyle}
                                    showRequiredStar={false}
                                    textColor='rgba(111, 191, 225, 1)'
                                    autoFocus
                                    setCount={setCount}
                                    onKeyDown={(e) => {
                                        errorChange('titleError', false);
                                        if (e.key === 'Enter') {
                                            toggleEdit();
                                        }
                                    }}
                                    onBlur={async () => {
                                        const nameExists = await metricService.isMetricNameUnique(
                                            metric?.name || '',
                                            evaId || '',
                                            metricId,
                                        );
                                        if (nameExists) {
                                            errorChange('titleError', true);
                                        }
                                    }}
                                />
                            </div>
                        ) : (
                            <div className='flex space-x-2 items-center justify-center group relative text-metric text-opacity-75 hover:text-opacity-100'>
                                <p
                                    className={clsx(
                                        'flex text-center text-tiny italic font-normal relative',
                                        error.titleError && 'border-b border-red',
                                    )}
                                >
                                    <p>{metric?.name}</p>
                                </p>
                                <button className='edit-button group-hover:opacity-100 opacity-0' onClick={toggleEdit}>
                                    <div>
                                        <EditIcon className='w-2.5 h-2.5' fill='#6FBFE1' fillOpacity={0.75} />
                                    </div>
                                </button>
                            </div>
                        )}
                    </div>
                    {/* show counter */}
                    <div className='flex justify-end space-x-4 items-center'>
                        <div className='text-sm font-normal'>{maxLength - count}</div>
                        <button
                            className='delete-metric'
                            onClick={(e) => {
                                e.stopPropagation();
                                if (mode === 'update') setIsOpenDeleteModal(true);
                                else closeMetric();
                            }}
                        >
                            <div>
                                <DeleteIcon className='w-3 h-3' fill='rgba(111, 191, 225, 1)' fillOpacity={0.75} />
                            </div>
                        </button>
                    </div>
                </div>
                <div className='add_metric_body no-scrollbar'>
                    <div className='h-full flex justify-between flex-col '>
                        <div>
                            {error.titleError && <div className='text-error px-10'>{t('metric_exist')}</div>}
                            <div className='mt-5'>
                                <Description
                                    id='metric-description'
                                    placeholder={t('new_metric_objectives')}
                                    maxLength={130}
                                    showCounter
                                    className='h-20 cursor-pointer'
                                    background='bg-metric bg-opacity-10'
                                    border={
                                        error.descriptionError
                                            ? 'border border-red'
                                            : 'border border-metric border-opacity-24'
                                    }
                                    textColor='text-metric text-opacity-75'
                                    value={metric?.description}
                                    onChange={(e) => setMetric((prev) => ({ ...prev, description: e }))}
                                />
                            </div>
                            <div className='mt-2 space-y-2'>
                                <div className='flex gap-2 items-center metric_select'>
                                    <label htmlFor=''>{t('metricType')}</label>
                                    <Select
                                        className='w-full'
                                        id='metric-format'
                                        entries={getFormatList()}
                                        rounded='rounded-md'
                                        placeholder={t('format')}
                                        textColor='text-metric'
                                        backgroundColor='bg-metric bg-opacity-10'
                                        borderColor='metric-select-border'
                                        iconColor='#6FBFE1BF'
                                        optionsClassname='bg-metric-100 shadow-metric'
                                        value={metric?.format}
                                        onChange={(value) =>
                                            setMetric((prev) => ({ ...prev, format: value, method: '', type: '' }))
                                        }
                                        hoverColor='bg-metric bg-opacity-10'
                                        marginTop={45}
                                        disabled={mode === 'update'}
                                        disabledBackgroundColor=''
                                        getName={(item) => t(`metricFormatList.${item}`)}
                                    />
                                </div>
                                {(metric?.format === FormatList.Ratio || metric?.format === FormatList.Score) && (
                                    <div className='flex gap-2 items-center metric_select'>
                                        <label htmlFor=''>{t('goldStandard')}</label>

                                        <div className='input-box flex justify-between'>
                                            <input
                                                type='number'
                                                value={metric?.goldStandard ?? ''}
                                                onChange={handleGoldStandardChange}
                                                onBlur={() => {
                                                    // If input is left empty, reset to 0
                                                    setMetric((prev) => ({
                                                        ...prev,
                                                        goldStandard:
                                                            prev?.goldStandard === null ? 0 : prev.goldStandard,
                                                    }));
                                                }}
                                                min={0}
                                                max={100}
                                            />
                                            <div className='percentage_icon flex justify-center items-center h-20 cursor-pointer rounded-md'>
                                                %
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className='flex gap-2 items-center metric_select'>
                                    <label htmlFor=''>{t('weight')}</label>

                                    <div className='flex gap-2 weight_slider  items-center'>
                                        <Slider
                                            value={metric?.weight}
                                            min={1}
                                            max={10}
                                            step={0.01}
                                            onChange={handleWeightSliderChange}
                                            sx={weightSliderStyle}
                                        />

                                        <div className='input-box flex justify-between'>
                                            <input readOnly type='number' value={metric?.weight?.toFixed(2)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <HorizontalSeparator className='mt-24 bg-metric bg-opacity-20 metric_horizontal_line' />
                            <div className='metric-child-component'>
                                <p className='mb-3'>{t('calculationMethods')}</p>
                                <div className='grid grid-cols-2 gap-3'>
                                    <div>
                                        <Select
                                            id='metric-frequency'
                                            entries={getFrequencyList()}
                                            rounded='rounded-md'
                                            placeholder={t('frequency')}
                                            textColor='text-metric'
                                            backgroundColor='bg-metric bg-opacity-10'
                                            borderColor='metric-select-border'
                                            iconColor='#6FBFE1BF'
                                            optionsClassname='bg-metric-100 shadow-metric'
                                            value={metric?.frequency}
                                            onChange={(value) =>
                                                setMetric((prev) => ({ ...prev, frequency: value, timeFrame: '' }))
                                            }
                                            hoverColor='bg-metric bg-opacity-10'
                                            marginTop={45}
                                            getName={(item) => t(`metricFrequency.${item}`)}
                                        />
                                    </div>
                                    <div className='metric-method'>
                                        <Select
                                            id='metric-method'
                                            entries={getCalculationMethodList(metric?.format)}
                                            rounded='rounded-md'
                                            placeholder={t('method')}
                                            textColor='text-metric'
                                            backgroundColor='bg-metric bg-opacity-10'
                                            borderColor='metric-select-border'
                                            iconColor='#6FBFE1BF'
                                            optionsClassname='bg-metric-100 shadow-metric'
                                            value={metric?.method}
                                            onChange={(value) => setMetric((prev) => ({ ...prev, method: value }))}
                                            hoverColor='bg-metric bg-opacity-10'
                                            marginTop={45}
                                            disabled={mode === 'update'}
                                            disabledBackgroundColor=''
                                            getName={(item) => t(`metricCalculationType.${item}`)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <HorizontalSeparator className='mt-5 bg-metric bg-opacity-20' />
                            <div className='metric-child-component'>
                                <p className='mb-3'>{t('aggregationRules')}</p>
                                <div className='grid grid-cols-2 gap-3'>
                                    <div>
                                        <Select
                                            id='metric-timeFrame'
                                            entries={getTimeFrameList(metric.frequency)}
                                            rounded='rounded-md'
                                            placeholder={t('timeFrame')}
                                            textColor='text-metric'
                                            backgroundColor='bg-metric bg-opacity-10'
                                            borderColor='metric-select-border'
                                            iconColor='#6FBFE1BF'
                                            optionsClassname='bg-metric-100 shadow-metric'
                                            value={metric?.timeFrame}
                                            onChange={(value) => setMetric((prev) => ({ ...prev, timeFrame: value }))}
                                            hoverColor='bg-metric bg-opacity-10'
                                            marginTop={45}
                                            getName={(item) => t(`metricFrequency.${item}`)}
                                        />
                                    </div>
                                    <div>
                                        <Select
                                            id='metric-type'
                                            entries={getAggregationTypeList(metric?.format)}
                                            rounded='rounded-md'
                                            placeholder={t('type')}
                                            textColor='text-metric'
                                            backgroundColor='bg-metric bg-opacity-10'
                                            borderColor='metric-select-border'
                                            iconColor='#6FBFE1BF'
                                            optionsClassname='bg-metric-100 shadow-metric'
                                            value={metric?.type}
                                            onChange={(value) => setMetric((prev) => ({ ...prev, type: value }))}
                                            hoverColor='bg-metric bg-opacity-10'
                                            marginTop={45}
                                            disabled={mode === 'update'}
                                            disabledBackgroundColor=''
                                            getName={(item) => t(`metricCalculationType.${item}`)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* <HorizontalSeparator className='mt-5 bg-metric bg-opacity-20' /> */}
                            {/* <div className='metric-child-component'>
                                <p className='mb-3'>{t('assign')}</p>
                                <div>
                                    <Select
                                        id='metric-category'
                                        entries={AssignList}
                                        rounded='rounded-md'
                                        placeholder={t('profile.fullName', {
                                            firstName: t('profile.firstName'),
                                            lastName: t('profile.lastName'),
                                        })}
                                        textColor='text-metric'
                                        backgroundColor='bg-metric bg-opacity-10'
                                        borderColor='metric-select-border'
                                        iconColor='#6FBFE1BF'
                                        optionsClassname='bg-metric-100 shadow-metric'
                                        getName={(item) => t(item.name)}
                                        value={assign}
                                        onChange={(value) => {
                                            setAssign(value);
                                            setMetric((prev) => ({ ...prev, assignId: value.memberId }));
                                        }}
                                        hoverColor='bg-metric bg-opacity-10'
                                        marginTop={45}
                                        disabledBackgroundColor='red'
                                    />
                                </div>
                            </div> */}
                            <HorizontalSeparator className=' mt-5 bg-metric bg-opacity-20 ' />
                            <div className='flex space-x-12 mt-5 items-center text-metric text-sm'>
                                <div className='font-medium'>{t('feedback')}</div>
                                <div className='flex space-x-4 font-normal'>
                                    <div>{t('deactivate')}</div>
                                    <Switch
                                        checked={metric?.feedback}
                                        onChange={(value) => setMetric((prev) => ({ ...prev, feedback: value }))}
                                        uncheckedColor='bg-metric bg-opacity-25'
                                        checkedColor='bg-metric bg-opacity-100'
                                        uncheckedThumbColor='bg-metric'
                                        checkedThumbColor='bg-metric-black'
                                    />
                                    <div>{t('activate')}</div>
                                </div>
                            </div>
                        </div>
                        <div className='create_metric_btn'>
                            <Button
                                className='w-full mt-5 '
                                variant='metric'
                                onClick={handleCreateOrUpdateMetric}
                                disabled={handleDisabled()}
                            >
                                {mode === 'create' ? t('btnNames.create') : t('btnNames.update')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(CreateNewMetric);
