import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { OversightData } from '../../store/OrganisationStore';
import { useServices } from '../ServiceProvider';
import HorizontalDatePicker from './HorizontalDatePicker.component';
import VerticalLine from './VerticalLine.component';

const Oversight = () => {
    const { id: organisationId } = useParams<{ id: string }>();
    const { organisation } = useServices();

    const { data } = useQuery<OversightData[]>(['getOversightData', organisationId], async () => {
        try {
            return await organisation.getOversightData(organisationId);
        } catch (e) {
            console.error(e);
            return [];
        }
    });

    return (
        <div className='oversight-container'>
            <HorizontalDatePicker oversightData={data || []}/>
            <VerticalLine />
        </div>
    );
};

export default Oversight;
