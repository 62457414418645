import { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as CopyIcon } from '../../../../../../../../src/assets/icons/Copy_Icon.svg';
import { ReactComponent as HistoryIcon } from '../../../../../../../../src/assets/icons/History_Icon.svg';
import { ReactComponent as BackArrow } from '../../../../../../../../src/assets/icons/leftArrow.svg';
import { ReactComponent as RightIcon } from '../../../../../../../../src/assets/icons/Right_Icon.svg';
import { ReactComponent as TerminalIcon } from '../../../../../../../../src/assets/icons/Terminal_Icon.svg';
import {
    setPrvProtocols,
    setNewProtocols,
    setSessionsId,
    setSessionsReport,
    setParentSessionId,
    setChildScreen,
    setGeneratedReportType,
    setNewMetrics,
    setPrvMetrics,
    setPrvPrompt,
    setSelectMetrics,
    setVersionsHistoryId,
    setInputVal,
} from '../../../../../../../Redux/SessionsProtocols';
import { RootState, useAppDispatch } from '../../../../../../../Redux/store';
import { EvaService } from '../../../../../../../services/eva/eva.service';
import { SessionReport } from '../../../../evaAnalytics/components/analyticsChart/types';
import { OptionData } from '../../ProtocolTypeList';
import ChatInput from '../Oversight/ChatInput';
import KpiMetricItem from './KpiMetricItem';
import MarkdownRenderer from './MarkdownRender';

interface ReportProps {
    reportError?: string | undefined;
    setReportError?: React.Dispatch<React.SetStateAction<string | undefined>>;
    prompt: string;
    reportName: string;
}

const Report: React.FC<ReportProps> = ({ reportError, prompt, reportName, setReportError }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const mainSessionIds = useSelector((state: RootState) => state.SessionsProtocols.SessionsId);

    const childScreen = useSelector((state: RootState) => state.SessionsProtocols.childScreen);
    const versionsHistoryId = useSelector((state: RootState) => state.SessionsProtocols.versionsHistoryId);
    const reportLoading = useSelector((state: RootState) => state.SessionsProtocols.ReportLoading);
    const checkCreateReport = useSelector((state: RootState) => state.SessionsProtocols.createReport);
    const newReport = useSelector((state: RootState) => state.SessionsProtocols.SessionsReport);

    const historyReport = childScreen === 'ViewHistoryReport';

    const sessionIds = childScreen === 'Report' ? mainSessionIds : versionsHistoryId;

    const evaId = useSelector((state: RootState) => state.SessionsProtocols.EvaId);
    const [reportData, setReportData] = useState<SessionReport>();
    const [copied, setCopied] = useState<boolean>(false);

    useEffect(() => {
        if (newReport !== null) {
            setReportData(newReport);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newReport, childScreen]);

    const contentRef1 = useRef<HTMLParagraphElement>(null);

    const handleCopyClick = (content: string) => {
        navigator.clipboard
            .writeText(content)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
                return;
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    const evaService = new EvaService();

    const { refetch, isLoading } = useQuery(
        ['report data', evaId, sessionIds],
        async () => {
            if (sessionIds && !checkCreateReport) {
                const reportData = await evaService.getReportSummaryById(evaId, sessionIds);
                dispatch(setNewProtocols(reportData?.new_protocols));
                dispatch(setPrvProtocols(reportData?.new_protocols));
                setReportData(reportData);
                dispatch(setGeneratedReportType(reportData.generatedReportType));
                dispatch(setParentSessionId(reportData.parent_session_id));
                dispatch(setSessionsReport(reportData));
                dispatch(setNewMetrics(reportData.new_metrics));
                dispatch(setPrvMetrics(reportData.new_metrics));
                dispatch(setPrvPrompt(reportData.prev_prompt));
            }
            return [];
        },
        {
            enabled: sessionIds ? true : false,
        },
    );

    //when click on back button all values are reset

    const back = () => {
        if (!reportLoading) {
            if (historyReport) {
                dispatch(setChildScreen('History'));
                if (reportData) {
                    dispatch(setSessionsId(reportData?.parent_session_id));
                }
                dispatch(setVersionsHistoryId(null));
            } else {
                dispatch(setSessionsId(null));
                dispatch(setChildScreen(''));
                dispatch(setSessionsReport(null));
            }
            if (setReportError) {
                setReportError(undefined);
                dispatch(setChildScreen(''));
            }
            dispatch(setNewProtocols([]));
            dispatch(setPrvProtocols([]));
            dispatch(setNewMetrics([]));
            dispatch(setPrvMetrics([]));
            dispatch(setSelectMetrics([]));
            dispatch(setParentSessionId(null));

            if (versionsHistoryId == null) {
                dispatch(setInputVal(''));
            }
        }
    };

    return (
        <>
            <div className='summaries_heading flex justify-between items-center'>
                <Tooltip id='history-tooltip' offset={5} place='bottom' className='tooltip-box show_arrow'>
                    <p> {t('history')} </p>
                </Tooltip>
                <div className='flex items-center gap-3'>
                    {/* */}
                    <button className='flex History_btn items-center relative justify-center ' onClick={back}>
                        <BackArrow />
                    </button>
                    <h1 className='report_heading'> {reportName} </h1>
                </div>

                <div className='div flex items-center gap-2 '>
                    {historyReport ? (
                        <button
                            className='flex History_btn items-center relative justify-center'
                            onClick={back}
                            data-tooltip-id='history-tooltip'
                        >
                            <HistoryIcon />
                        </button>
                    ) : (
                        <button
                            data-tooltip-id='history-tooltip'
                            className='flex History_btn items-center relative justify-center'
                            onClick={() => {
                                if (newReport) {
                                    dispatch(setChildScreen('History'));
                                    dispatch(setSessionsId(newReport?.session_id));
                                }
                            }}
                        >
                            <HistoryIcon />
                        </button>
                    )}
                </div>
            </div>

            {!historyReport && (
                <div className='creation_date'>
                    <ChatInput />
                </div>
            )}

            <div
                className={`creation_date text-right flex justify-end ${
                    historyReport ? '' : 'report_creation_date'
                } items-center`}
            >
                <span>
                    {' '}
                    {t('creation_date', {
                        date: moment(reportData?.created_at).format('DD/MM/YY'),
                    })}{' '}
                </span>
            </div>

            <div className='executive_summaries_main'>
                <div className={`${historyReport ? 'history_report' : ''} executive_summaries relative`}>
                    {reportError ? (
                        <p className='error'>{reportError}</p>
                    ) : (
                        <>
                            <div
                                className={`${
                                    reportLoading ? 'summaries_height' : ''
                                } executive_summaries_detail scrollbar`}
                            >
                                <h1> {`${reportData?.report_category} : ${reportData?.report_type}`} </h1>

                                <div ref={contentRef1}>
                                    <MarkdownRenderer>{reportData?.report_text ?? ''}</MarkdownRenderer>
                                </div>
                            </div>

                            {!reportLoading && (
                                <div
                                    className='w-2 ml-auto copy_icon cursor-pointer '
                                    onClick={() => handleCopyClick(contentRef1.current?.innerText || '')}
                                >
                                    <CopyIcon />
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className='kpis_box kpis_box1 flex gap-2 items-center '>
                    <div className='flex gap-2 items-center '>
                        {reportData?.new_metrics && reportData?.new_metrics?.length > 0 && (
                            <div className='flex  meter_box items-center gap-1'>
                                <p data-tooltip-id='report-tooltip'>{reportData?.new_metrics?.length} Metrics</p>

                                <Tooltip id='report-tooltip' offset={5} place='top' className='tooltip-box show_arrow'>
                                    {reportData?.new_metrics?.map((metric, index) => (
                                        <KpiMetricItem key={index} metric={metric} OptionData={OptionData} />
                                    ))}
                                </Tooltip>
                            </div>
                        )}
                    </div>
                    <div
                        className='kpis_history w-full flex gap-2 items-center   relative'
                        data-tooltip-id='report-prompt'
                    >
                        <div className='terminal terminal_icon'>{<TerminalIcon />}</div>
                        <div
                            className='copy_icon cursor-pointer'
                            onClick={() => handleCopyClick(reportData?.prompt ?? prompt)}
                        >
                            {copied ? <RightIcon /> : <CopyIcon />}
                            <Tooltip id='report-prompt' offset={5} place='top' className='tooltip-box show_arrow'>
                                <p>{reportData?.prompt ?? prompt}</p>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Report;
