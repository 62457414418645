import { useState } from 'react';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Menu from '@mui/material/Menu';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as FilterIcon } from '../../../../../../../assets/icons/icon-filter.svg';
import { ReactComponent as SortIcon } from '../../../../../../../assets/icons/SortArrow_Icon.svg';
import { RootState } from '../../../../../../../Redux/store';

export enum ActivitySortType {
    Newest = 'Newest First',
    Oldest = 'Oldest First',
    AtoZ = 'Sort A-Z',
    ZtoA = 'Sort Z-A',
}

const ActivitySort = ({ handleSort }: { handleSort: (sortType: ActivitySortType) => void }) => {
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const { t } = useTranslation();
    const isOpenBluePrintModal = useSelector((state: RootState) => state.EvaInfo.isOpenBluePrintModal);

    const open = Boolean(anchor);
    const handleClose = () => {
        setAnchor(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchor(event.currentTarget);
    };

    return (
        <div>
            <button
                className='flex items-center text-sm text-white text-opacity-75 hover:text-opacity-100 sort-btn'
                onClick={(e) => handleClick(e)}
            >
                {isOpenBluePrintModal ? <SortIcon /> : <FilterIcon className='w-3 h-3' fill='#0F104480' />}

                <KeyboardArrowDownOutlinedIcon
                    className='activity-sort-icon'
                    sx={{
                        color: '#0F104480',
                        fontSize: '1rem',
                    }}
                />
            </button>
            <Menu
                id='metric-sort-menu'
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    className: 'sort-menu-list',
                }}
                disableAutoFocus
            >
                <button
                    className='w-full flex text-sm p-2 text-darker text-opacity-75 hover:bg-primary-darker hover:bg-opacity-3'
                    onClick={() => {
                        handleSort(ActivitySortType.Newest);
                        handleClose();
                    }}
                >
                    <p>{t('sortOptions.Newest First')}</p>
                </button>
                <button
                    className='w-full flex text-sm p-2 text-darker text-opacity-75 hover:bg-primary-darker hover:bg-opacity-3'
                    onClick={() => {
                        handleSort(ActivitySortType.Oldest);
                        handleClose();
                    }}
                >
                    <p>{t('sortOptions.Older First')}</p>
                </button>
                <button
                    className='w-full flex text-sm p-2 text-darker text-opacity-75 hover:bg-primary-darker hover:bg-opacity-3'
                    onClick={() => {
                        handleSort(ActivitySortType.AtoZ);
                        handleClose();
                    }}
                >
                    <p>{t('sortOptions.Sort A-Z')}</p>
                </button>
                <button
                    className='w-full flex text-sm p-2 text-darker text-opacity-75 hover:bg-primary-darker hover:bg-opacity-3'
                    onClick={() => {
                        handleSort(ActivitySortType.ZtoA);
                        handleClose();
                    }}
                >
                    <p>{t('sortOptions.Sort Z-A')}</p>
                </button>
            </Menu>
        </div>
    );
};

export default ActivitySort;
