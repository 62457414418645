import { appInsights } from '../../AppInsights';
import { get } from '../../utils/network';

export class ActivityService {
    getAllActivity(
        evaId: string,
        evaType: string,
        sortVal: string,
        sortFilterType: string,
        search: string,
        page: string,
    ) {
        try {
            const queryParams = new URLSearchParams({
                evaType,
                sortBy: sortVal.toLowerCase(),
                type: sortFilterType,
                search,
                page,
            });
            return get(`/activity/${evaId}?${queryParams.toString()}`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }
}
