import * as Sentry from '@sentry/react';
import { io } from 'socket.io-client';

import { appInsights } from '../../AppInsights';
import { EvaType, MetaMarkerData } from '../../components/organisation/copilotOrganisation/eva/types';
import { Websites } from '../../components/organisation/copilotOrganisation/evaAnalytics/types';
import { store } from '../../store';
import { Organisation } from '../../store/OrganisationStore';
import { del, get, post, put } from '../../utils/network';
import { CreatePayment, SubscriptionTF, UpdatePaymentMethod } from '../payments/payments.service';

const ENV_BASE_URL = process.env.REACT_APP_BACK_END_URL || 'http://localhost:3000';
const BASE_URL = ENV_BASE_URL[ENV_BASE_URL.length - 1] === '/' ? ENV_BASE_URL.slice(0, -1) : ENV_BASE_URL;

const socket = io(BASE_URL, {
    autoConnect: false,
});
export class OrganisationService {
    connect() {
        socket.connect();

        socket.on('removedMember', ({ to, organisationId }: { to: number; organisationId: string }) => {
            if (store.user.userData && to === store.user.userData.id) {
                if (window.location.href.includes('/organisation') && window.location.href.includes(organisationId)) {
                    window.location.href = '/home';
                }
            }
        });

        socket.on('updateMember', ({ to, organisationId }: { to: number; organisationId: string }) => {
            if (store.user.userData && to === store.user.userData.id) {
                if (window.location.href.includes('/organisation') && window.location.href.includes(organisationId)) {
                    window.location.href = '/home';
                }
            }
        });
    }

    async createUpdateOrganisation(createUpdateOrganisationDto: Organisation) {
        try {
            const response = await post('/organisation/create-or-update', createUpdateOrganisationDto);
            return response;
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
            throw err;
        }
    }

    getOrganisation(id: string) {
        try {
            return get(`/organisation/${id}`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    async getPaymentMethods(id: string) {
        try {
            const response = await post(`/organisation/payment-methods`, { id });
            store.user.setPaymentMethods(response);
            return response;
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    addPaymentMethod(input: CreatePayment, id: string) {
        try {
            return post(`/organisation/payment-method`, { input, id });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    updatePaymentMethod(input: UpdatePaymentMethod, id: string) {
        try {
            return put(`/organisation/payment_method`, { input, id });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    createOrUpdateSubscription(input: SubscriptionTF) {
        try {
            return post(`/organisation/subscription`, input);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    uploadLogo(documentId: string, id: string) {
        try {
            return post(`/organisation/upload-logo`, { documentId, id });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    setDefaultPaymentMethod(id: string, payment_method: string) {
        try {
            return put(`/organisation/payment_method/set_default`, { payment_method, id });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    getSubscription(id: string) {
        try {
            return get(`/organisation/subscription/${id}`);
        } catch (err) {
            console.error(err);
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    downgradeSubscription(id: string) {
        try {
            return post(`/organisation/downgrade/${id}`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    inviteMember(email: string, organisationId: string, role: string) {
        try {
            return post(`/organisation/invite-member`, { email, organisationId, role });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    updateMemberInvite(email: string) {
        try {
            return post(`/organisation/update-member-invite`, { email });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    getMembers(organisationId: string) {
        try {
            return get(`/organisation/members/${organisationId}`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    voidCanceledSubscription(organisationId: string) {
        try {
            return put(`/organisation/void-canelled-subscription/`, { organisationId });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    resumeSubscription(input: SubscriptionTF) {
        try {
            return put(`/organisation/resume-subscription/`, input);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    createEVA(input: MetaMarkerData, id: string) {
        try {
            return post(`/organisation/${id}/eva`, { input });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    getEvaByOrganisationId(id: string, type: EvaType) {
        try {
            return get(`/organisation/${id}/eva?type=${type}`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    updateEVA(input: MetaMarkerData, id: string, evaId: string) {
        try {
            return put(`/organisation/${id}/eva/${evaId}`, { input });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    getEVA(id: string, evaId: string, type: EvaType) {
        try {
            return get(`/organisation/${id}/eva/${evaId}?type=${type}`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    getEvaGraphAndLog(id: string, evaId: string) {
        try {
            return get(`/organisation/${id}/eva/${evaId}/graph`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    getInvoices(id: string) {
        try {
            return get(`/organisation/invoices/${id}`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    updateMember(organisationId: string, memberId: number, role: string) {
        try {
            return put(`/organisation/update-member`, { organisationId, memberId, role });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    deleteMember(organisationId: string, memberId: number) {
        try {
            return put(`/organisation/delete-member`, { organisationId, memberId });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    makeEvaEnableDisable(input: { isEvaEnable: boolean }, id: string, evaId: string) {
        try {
            return put(`/organisation/${id}/eva/${evaId}/makeEvaEnableDisable`, input);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    deletePaymentMethod(id: string, payment_method: string) {
        try {
            return del(`/organisation/${id}/detach_payment/${payment_method}`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    getOrganisationWithInvites() {
        try {
            return get('/organisation/with-invites');
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    deleteEva(id: string, evaId: string) {
        try {
            return del(`/organisation/${id}/eva/${evaId}/delete-eva`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    getAllArchivedEva(id: string) {
        try {
            return get(`/organisation/${id}/archived-eva`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    archiveEva(evaId: string) {
        try {
            return post(`/organisation/archive-eva`, { evaId });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    unarchiveEva(organisationId: string, evaId: string, publishObj: MetaMarkerData, isEvaEnable: boolean) {
        try {
            return post(`/organisation/unarchive-eva`, {
                organisationId,
                evaId,
                createEvaPayload: { input: publishObj },
                isEvaEnable,
            });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    getEvaByEvaId(evaId: string, type: EvaType) {
        try {
            return get(`/organisation/eva/${evaId}?type=${type}`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    deleteEvaByEvaId(evaId: string) {
        try {
            return del(`/organisation/eva/${evaId}`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    duplicateEva(evaId: string) {
        try {
            return post(`/organisation/duplicate-eva`, { evaId });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    getEvaListByOrganisationId(id: string) {
        try {
            return get(`/organisation/${id}/eva-list`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    checkUserPermission(organisationId: string) {
        try {
            return get(`/organisation/check-user-permission/${organisationId}`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    updateBlocklistDomain(blocklistDomain: Websites[], organisationId: string) {
        return post(`/organisation/blocklist-domain`, { blocklistDomain, organisationId });
    }

    getComplianceMonitoring(organisationId: string) {
        try {
            return get(`/organisation/${organisationId}/compliance-monitoring`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    getAllDomainByTLD(organisationId: string) {
        try {
            return get(`/organisation/${organisationId}/tld`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    getAllDomainCategory(organisationId: string) {
        try {
            return get(`/organisation/${organisationId}/domain-category`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    isNaviDataExists(organisationId: string) {
        try {
            return get(`/organisation/${organisationId}/is-navi-exist`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    getNaviStatus(organisationId: string) {
        try {
            return get(`/organisation/${organisationId}/navi-status`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    updateNaviStatus(organisationId: string, status: boolean) {
        try {
            return put(`/organisation/${organisationId}/navi-status`, { status });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    removeInvitedMember(email: string, organisationId: string) {
        try {
            return post(`/organisation/${organisationId}/remove-invited-member`, { email });
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }
    leaveOrganisation(organisationId: string) {
        try {
            return put(`/organisation/${organisationId}/leave`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    deleteOrganisation(organisationId: string) {
        try {
            return del(`/organisation/${organisationId}`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    archiveCurrentEva(organisationId: string) {
        try {
            return post(`/organisation/${organisationId}/archive-current-eva`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    getOversightData(organisationId: string) {
        try {
            return get(`/organisation/${organisationId}/oversight-data`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }
}
