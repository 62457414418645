import { makeAutoObservable } from 'mobx';

import { DocumentInfo } from './DocumentsStore';

export const ORGANISATION_TIERS = {
    SHIELD: 'SHIELD',
    OVERSIGHT: 'OVERSIGHT',
};

export const SUBSCRIPTION_STATUS = {
    ACTIVE: 'active',
    CANCELED: 'canceled',
    TRIALING: 'trialing',
};

export const SUBSCRIPTION_BUTTON_LABEL = {
    CURRENT_TIER: 'Current Tier',
    SUBSCRIBE: 'Subscribe',
    UPGRADE: 'Upgrade',
    DOWNGRADE: 'Downgrade',
    START_TRIAL: 'Start Trial',
    CANCEL_UNSUBSCRIPTION: 'Revert Cancellation',
    CHANGE_TIER: 'Change Tier',
};

export type Transaction = {
    date: Date;
    id: string;
    description: string;
    paymentMethod: string;
    amount: number;
};

export type Subscription = {
    id: string;
    subscriptionPricingId: string;
    organisationId: string;
    userId: number;
    subscriptionPoductId: string;
    status: string;
};

export type Organisation = {
    id?: number;
    organisationId: string;
    organisationName: string;
    businessType: string;
    crn?: string;
    vat?: string;
    website?: string;
    phone?: string;
    address: string;
    postcode: string;
    city: string;
    country: string;
    logo?: string;
    email?: string;
    subscription?: Subscription;
    emailVerified?: boolean;
    taxId?: string;
    role?: string;
};

export type Member = {
    id: number;
    name: string;
    email: string;
    role: string;
    eva: boolean;
    documents: DocumentInfo[];
    createdById: number;
};

export type EvaArchive = {
    evaId: string;
    title: string;
    description: string;
    lastEdited: string;
    daysUsed: number;
    triggers: number;
    performance: number;
};

export type StripeEvents = {
    id: string;
    userId: number;
    organisationId: string;
    status: string;
    type: string;
    description: string;
    amount: number;
    createdAt: string;
    pricingName: string;
};

export type ComplianceMonitoring = {
    id: string;
    organisationId: string;
    createdAt: string;
    pageVisitCount: number;
    domain: string;
    status: boolean;
    url: string;
};

export type DomainTLD = {
    tld: string;
    count: number;
    percentage: number;
};

export type DomainCategory = {
    id: string;
    category: string;
    colour: string;
    percentage: number;
    count: number;
};

export type OversightProtocolCategory = {
    category: string;
    count: number;
};

export type OversightTrigger = {
    triggeredDate: Date;
    count: number;
}

export type OversightSnapshot = {
    id: string;
    startDate: Date;
    endDate: Date;
    name: string;
    report: OversightReport[];
}

export type OversightReport = {
    created_at: Date;
    report_name: string;
};

export type OversightMetrics = {
    name: string;
    format: string;
}

export type OversightData = {
    evaId: string;
    evaName: string;
    evaDescription: string;
    tooltipName?: string;
    date: Date;
    createdAt: Date;
    updatedAt: Date;
    endedAt: Date;
    isPublished: boolean;
    type?: 'active' | 'updated';
    size?: 'small' | 'medium' | 'large' | 'today' | 'create' | 'snapshot';
    triggeredData: OversightTrigger[];
    snapshotData: OversightSnapshot[];
    reportData: OversightReport[];
    totalProtocolCount: number;
    protocolCategoryCount: OversightProtocolCategory[];
    metrics: OversightMetrics[];
}

export class OrganisationStore {
    private organisations: Organisation[] = [];
    organisationData: Organisation | undefined = undefined;
    organisationMembers: Member[] = [];
    archivedEVAs: EvaArchive[] = [];
    publishedEVAExist = false;
    selectedTier: string | undefined = localStorage.getItem('selectedTier') || ORGANISATION_TIERS.SHIELD;
    stripeEvents: StripeEvents[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    init(organisations: Organisation[]) {
        this.organisations = organisations;
    }

    getAll() {
        return this.organisations;
    }

    setOrganisationData(organisation: Organisation) {
        this.organisationData = organisation;
    }

    getOrganisationData() {
        return this.organisationData;
    }

    setOrganisationMembers(members: Member[]) {
        this.organisationMembers = members;
    }

    getOrganisationMembers() {
        return this.organisationMembers;
    }

    getArchivedEVAs() {
        return this.archivedEVAs;
    }

    setArchivedEVAs(archivedEVAs: EvaArchive[]) {
        this.archivedEVAs = archivedEVAs;
    }

    deleteArchivedEVA(evaId: string) {
        if (this.archivedEVAs) {
            const filtered = this.archivedEVAs.filter((eva) => {
                if (eva) return eva.evaId !== evaId;
                return true;
            });
            this.archivedEVAs = filtered;
        }
    }

    duplicateArchivedEVA(eva: EvaArchive) {
        this.archivedEVAs.push(eva);
    }

    setPublishedEVAExist(value: boolean) {
        this.publishedEVAExist = value;
    }

    setSelectedTier(tier: string) {
        localStorage.setItem('selectedTier', tier);
        this.selectedTier = tier;
    }

    getStripeEvents() {
        return this.stripeEvents;
    }

    setStripeEvents(events: StripeEvents[]) {
        this.stripeEvents = events;
    }
}
