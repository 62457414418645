import { months, OversightTimeRange, shortMonths } from '../../utils/constants';

const today = new Date();

export const generate24Hours = (year: number, month: number, day: number) => {
    const result = Array.from({ length: 24 }, (_, i) => {
        const newDate = new Date(year, month, day || today.getDate());
        newDate.setHours(i + 1, 0, 0, 0); // Set hours 1AM to 12AM
        return newDate;
    });
    return result;
};

export const generate7Days = (year: number, month: number, week: string) => {
    const [startDayStr, endDayStr] = week.split(' ')[0].split('-');
    const startDay = parseInt(startDayStr, 10);
    const endDay = parseInt(endDayStr, 10);

    // Generate the date range based on startDay and endDay
    const days = Array.from({ length: endDay - startDay + 1 }, (_, i) => {
        return new Date(year, month, startDay + i);
    });

    return days;
};

export const generate30Days = (year: number, month: number) => {
    // if (year === today.getFullYear() && month === today.getMonth()) {
    //     // If current month, center today
    //     return Array.from({ length: 30 }, (_, i) => {
    //         const newDate = new Date(today);
    //         newDate.setDate(today.getDate() + i - 14); // 14 before, 15 after
    //         return newDate;
    //     });
    // } else {
    //     // If other month, show full month
    //     const daysInMonth = new Date(year, month + 1, 0).getDate();
    //     return Array.from({ length: daysInMonth }, (_, i) => new Date(year, month, i + 1));
    // }
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    return Array.from({ length: daysInMonth }, (_, i) => new Date(year, month, i + 1));
};

export const generate3Months = (year: number, month: number) => {
    const months = [
        new Date(year, month - 1, 1), // Previous month
        new Date(year, month, 1), // Current month
        new Date(year, month + 1, 1), // Next month
    ];
    return months;
};

export const generateYear = (year: number) => {
    const months = Array.from({ length: 12 }, (_, i) => new Date(year, i, 1));
    return months;
};

//date display based on time range
export const getDateBasedOnTimeRange = (date: Date, timeRange: string) => {
    switch (timeRange) {
        case OversightTimeRange[24]:
            return date.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true });
        case OversightTimeRange[7]:
            return date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
        case OversightTimeRange[30]:
            return date.getDate();
        case OversightTimeRange[90]:
            return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        case OversightTimeRange[365]:
            return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        default:
            return date.getDate();
    }
};

export const displayStateBasedOnTimeRange = (timeRange: string, date: Date) => {
    const today = new Date();
    switch (timeRange) {
        case OversightTimeRange[24]: {
            const hours = date.getHours();
            const todayHours = today.getHours();
            return hours === todayHours;
        }
        case OversightTimeRange[7]:
            return date.toDateString() === today.toDateString();
        case OversightTimeRange[30]:
            return date.toDateString() === today.toDateString();
        case OversightTimeRange[90]:
            return date.getMonth() === today.getMonth();
        case OversightTimeRange[365]:
            return date.getMonth() === today.getMonth();
        default:
            return false;
    }
};
export const getSelectedDateClass = (date: Date, timeRange: string, selectedDate: Date | null) => {
    switch (timeRange) {
        case OversightTimeRange[24]:
            return date.getHours() === selectedDate?.getHours() ? 'selected-date' : '';
        case OversightTimeRange[7]:
            return date.toDateString() === selectedDate?.toDateString() ? 'selected-date' : '';
        case OversightTimeRange[30]:
            return date.toDateString() === selectedDate?.toDateString() ? 'selected-date month' : '';
        case OversightTimeRange[90]:
            return date.getMonth() === selectedDate?.getMonth() ? 'selected-date' : '';
        case OversightTimeRange[365]:
            return date.getMonth() === selectedDate?.getMonth() ? 'selected-date' : '';
        default:
            return date.toDateString() === selectedDate?.toDateString() ? 'selected-date' : '';
    }
};

export const getDifferentTimeRangeMinWidth = (timeRange: string) => {
    switch (timeRange) {
        case OversightTimeRange[24]:
            return 'day';
        case OversightTimeRange[7]:
            return 'week';
        case OversightTimeRange[30]:
            return 'month';
        case OversightTimeRange[90]:
            return 'quarter';
        case OversightTimeRange[365]:
            return 'year';
        default:
            return 'month';
    }
};

// Generate dropdown options based on time range
export const generateDropdownOptions = (timeRange: string, year: number, month: number) => {
    const totalDays = new Date(year, month + 1, 0).getDate();

    switch (timeRange) {
        case OversightTimeRange[24]: // Daily
            return Array.from({ length: totalDays }, (_, i) => `${shortMonths[month]} ${i + 1}, ${year}`);

        case OversightTimeRange[7]: { // Weekly
            return Array.from({ length: Math.ceil(totalDays / 7) }, (_, i) => {
                const startDay = i * 7 + 1;
                const endDay = Math.min(startDay + 6, totalDays);
                return `${startDay}-${endDay} ${shortMonths[month]}, ${year}`;
            });
        }

        case OversightTimeRange[30]: // Monthly
            return months.map((m) => `${m} ${year}`);

        case OversightTimeRange[90]: // Quarterly
            return ['Jan - Mar', 'Apr - Jun', 'Jul - Sep', 'Oct - Dec'].map((q) => `${q} ${year}`);

        case OversightTimeRange[365]: // Yearly
            return Array.from({ length: 25 }, (_, i) => (year - i).toString());

        default:
            return months.map((m) => `${m} ${year}`);
    }
};

export const generateDefaultDropdownValue = (timeRange: string, year: number, month: number, day: number) => {
    switch (timeRange) {
        case OversightTimeRange[24]: // Daily
            return `${shortMonths[month]} ${day}, ${year}`;

        case OversightTimeRange[7]: { // Weekly
            const totalDays = new Date(year, month + 1, 0).getDate();
            let startDay = 1;
            let endDay = 7;

            while (endDay < totalDays && day > endDay) {
                startDay += 7;
                endDay = Math.min(startDay + 6, totalDays);
            }
            return `${startDay}-${endDay} ${shortMonths[month]}, ${year}`;
        }

        case OversightTimeRange[30]: // Monthly
            return `${months[month]} ${year}`;

        case OversightTimeRange[90]: { // Quarterly
            const quarterLabels = ['Jan - Mar', 'Apr - Jun', 'Jul - Sep', 'Oct - Dec'];
            const quarterIndex = Math.floor(month / 3);
            return `${quarterLabels[quarterIndex]} ${year}`;
        }

        case OversightTimeRange[365]: // Yearly
            return year.toString();

        default:
            return `${shortMonths[month]} ${year}`;
    }
};

export const monthsMap: Record<string, number> = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
};

export const quarterStartMonths: Record<string, number> = {
    'Jan - Mar': 0,
    'Apr - Jun': 3,
    'Jul - Sep': 6,
    'Oct - Dec': 9,
};
